import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  Modal,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "function/useAuth";
import config from "../../../config";
import api from "../../../api";
const Users = () => {
  const [id, setId] = useState();
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [prefixList, setPrefixList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [prefix, setPrefix] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [department, setDepartment] = useState("");
  const [debtCenter, setDebtCenter] = useState("");
  const [debtCenterList, setDebtCenterList] = useState([]);
  const [openCreateSuccess, setOpenCreateSuccess] = useState(false);
  const [openCreateFail, setOpenCreateFail] = useState(false);
  const [userHistory, setUserHistory] = useState([]);
  const [startDate, setstartDate] = useState(new Date());
  const [birthDay, setBirthDay] = useState(new Date());
  const [salary, setSalary] = useState("");
  const [nickName, setNickName] = useState("");

  useAuth();

  function getDdlPrefix() {
    axios
      .get(`${config.mainAPI}/prefix-list`)
      .then((response) => {
        console.log("value", response.data.data);
        setPrefixList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getPosition = () => {
    api
      .get(`${config.mainAPI}/getPosition`)
      .then((response) => {
        console.log("value", response.data.data);
        setPositionList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getDepartment = () => {
    api
      .get(`${config.mainAPI}/getDepartment`)
      .then((response) => {
        console.log("value", response.data.data);
        setDepartmentList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOpen = () => {
    getPosition();
    getDepartment();
    setPassword(randomPassword(5));
    setOpenCreate(true);
  };

  function randomPassword(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const handleClose = () => {
    setOpenCreate(false);
    clearCreateData();
  };

  const clearCreateData = () => {
    setUsername("");
    setPassword("");
    setRole("");
    setDebtCenter("");
    setFirstName("");
    setLastName("");
    setPrefix("");
    setRole("");
    setDepartment("");
    setNickName("");
    setBirthDay(new Date());
    setstartDate(new Date());
    setSalary("");
  };

  const handleChange = (event) => {
    setHospital(event.target.value);
  };

  const handleSubmit = (event) => {
    const payload = {
      username: username,
      password: password,
      role: role,
      prefix: prefix,
      firstname: firstname,
      lastname: lastname,
      debtCenter: debtCenter,
      department: department,
      nickName: nickName,
      birthDay: birthDay,
      startDate: startDate,
      salary: salary,
    };
    api
      .post(`${config.mainAPI}/createUserInCompany`, {
        ...payload,
      })
      .then(function (response) {
        getData();
        setOpenCreate(false);
        clearCreateData();
        setUserHistory(response.data.results);
        setOpenCreateSuccess(true);
        console.log("response", response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeRole = (event) => {
    setRole(event.target.value);
    event.target.value == "bank" ? setUsername("gsb" + username) : null;
  };

  const changeDebtCenter = (event) => {
    console.log(event.target.value);
    setDebtCenter(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Edit
  const handleOpenEdit = (row) => {
    setId(row.id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    // setRole("");
    // setDepartment("");
    // setId("");
    // setPassword("");
    // setDebtCenter("");
    // setPrefix("");
    // setFirstName("");
    // setLastName("");
    // setNickName("");
    // setBirthDay(new Date());
    // setstartDate(new Date());
    // setSalary("");
  };
  const handleSubmitEdit = (event) => {
    const id = id;
    event.preventDefault();
    const firstname = event.target.elements.firstname.value;
    const lastname = event.target.elements.lastname.value;
    const role_status = event.target.elements.role.value;
    const hospital_id = event.target.elements.hospital.value;
    axios
      .put(`${config.mainAPI}/user/${id}`, {
        firtstname: firstname,
        lastname: lastname,
        role: role_status,
      })
      .then(function (response) {
        getData();
        setOpenEdit(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Delete
  const handleOpenDelete = (row) => {
    setId(row.id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setId(null);
    setOpenDelete(false);
  };

  const handleSubmitDelete = () => {
    const id = id;
    api
      .delete(`${config.mainAPI}/users/${id}`)
      .then((response) => {
        setOpenDelete(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function getDdlDebtCenter() {
    api
      .get(`${config.mainAPI}/debtCenter`)
      .then((response) => {
        setDebtCenterList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getData();
    getDdlPrefix();
    getDdlDebtCenter();
  }, []);

  const getData = () => {
    api
      .get(`${config.mainAPI}/users`)
      .then((response) => {
        const value = response?.data.data;
        console.log("value", value);
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              item.user_username,
              item.user_fullname,
              item.user_position
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 30 },
    { id: "id", label: "ไอดี", minWidth: 30 },
    { id: "name", label: "ชื่อ-นามสกุล", minWidth: 100 },
    { id: "role", label: "ตำแหน่ง", minWidth: 100 },
    // {
    //   id: "mange",
    //   label: "จัดการ",
    //   minWidth: 50,
    //   render: (row) => (
    //     <>
    //       <IconButton aria-label="edit" onClick={() => handleOpenEdit(row)}>
    //         <EditIcon />
    //       </IconButton>
    //       <IconButton
    //         aria-label="delete"
    //         color="error"
    //         onClick={() => handleOpenDelete(row)}
    //       >
    //         <DeleteIcon />
    //       </IconButton>
    //     </>
    //   ),
    // },
  ];

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, id, name, role) {
    return { order, id, name, role };
  }

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    // setError(null);
    setData(christDate);
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  return (
    <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
      >
        จัดการสมาชิก
      </Typography>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          float: "right",
          marginRight: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          color: "#000",
          borderColor: "#000",
        }}
        color="success"
        startIcon={<AddCircleIcon />}
      >
        เพิ่มผู้ใช้งาน
      </Button>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.order}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center">
                        {column.render ? column.render(row) : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        open={openCreate}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant="h3"
            sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
          >
            เพิ่มผู้ใช้งาน
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <form onSubmit={handleSubmit}> */}
            <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>
                  ตำแหน่ง: <span style={{ color: "red" }}> *</span>
                  <Select
                    color="dark"
                    id="role"
                    name="role"
                    value={role}
                    onChange={changeRole}
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    {positionList &&
                      positionList.map((column) => (
                        <MenuItem
                          key={column.position_id}
                          value={column.position_name_en}
                        >
                          {column.position_name_th}
                        </MenuItem>
                      ))}
                  </Select>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>แผนก:</Typography>
                <Select
                  color="dark"
                  id="department"
                  name="department"
                  value={department}
                  onChange={(event) => setDepartment(event.target.value)}
                  fullWidth
                  disabled={!role || role == "bank"}
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {departmentList &&
                    departmentList.map((column) => (
                      <MenuItem
                        key={column.department_id}
                        value={column.department_name_en}
                      >
                        {column.department_name_th}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>
                  ไอดี: <span style={{ color: "red" }}> *</span>
                </Typography>
                <TextField
                  id="id"
                  name="id"
                  type="text"
                  color="dark"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    alignSelf: "center",
                  }}
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontSize: "16px" }}>รหัสผ่าน:</Typography>
                <TextField
                  id="password"
                  name="password"
                  type="text"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  value={password}
                  disabled
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
            </Grid>

            {role && role != "bank" ? (
              <>
                <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      คำนำหน้า: <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Select
                      color="dark"
                      value={prefix}
                      onChange={(e) => setPrefix(e.target.value)}
                      variant="outlined"
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      {prefixList &&
                        prefixList.map((column) => (
                          <MenuItem
                            key={column.prefix_id}
                            value={column.prefix_name_th}
                          >
                            {column.prefix_name_th}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ชื่อ: <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <TextField
                      id="firstName"
                      name="firstName"
                      type="text"
                      color="dark"
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>สกุล:</Typography>
                    <TextField
                      id="lastName"
                      name="lastName"
                      type="text"
                      color="dark"
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ชื่อเล่น: <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <TextField
                      id="nickName"
                      name="nickName"
                      type="text"
                      color="dark"
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      onChange={(event) => setNickName(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>วันเกิด:</Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={birthDay}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setBirthDay,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันเริ่มงาน: <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <WatDatePicker
                      className="custom-date-picker"
                      value={startDate}
                      onChange={(christDate, buddhistDate) =>
                        handleDatePickerChange(
                          setstartDate,
                          christDate,
                          buddhistDate
                        )
                      }
                      dateFormat={"yyyy-MM-dd"}
                      displayFormat={"DD/MM/YYYY"}
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เงินเดือน:
                    </Typography>
                    <TextField
                      id="salary"
                      name="salary"
                      type="text"
                      color="dark"
                      fullWidth
                      variant="outlined"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                      onChange={(event) => setSalary(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </>
            ) : role && role == "bank" ? (
              <>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} sm={6} sx={{ alignSelf: "center" }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ศูนย์หนี้:
                    </Typography>
                    <Select
                      color="dark"
                      id="debtCenter"
                      name="debtCenter"
                      value={debtCenter}
                      onChange={changeDebtCenter}
                      fullWidth
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      {debtCenterList &&
                        debtCenterList.map((column) => (
                          <MenuItem
                            key={column.debt_center_id}
                            value={column.debt_center_name}
                          >
                            {column.debt_center_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </>
            ) : null}

            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ marginLeft: 3 }}
                onClick={handleSubmit}
                disabled={!role || !username}
                //   type="submit"
              >
                บันทึก
              </Button>
            </Box>
            {/* </form> */}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* แก้ไขผู้ใช้งาน */}
      <Dialog
        open={openEdit}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#086c3c" }}
        >
          <Typography variant="h3" sx={{ fontWeight: 500, color: "#fff" }}>
            เพิ่มสมาชิก
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit}>
              <Grid container sx={{ marginTop: 5 }}>
                <Grid xs={3} sx={{ alignSelf: "center" }}>
                  <Typography sx={{ fontSize: "18px" }}>ชื่อ</Typography>
                </Grid>
                <Grid xs={9}>
                  <TextField
                    id="firstname"
                    name="firstname"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: 5 }}>
                <Grid xs={3} sx={{ alignSelf: "center" }}>
                  <Typography sx={{ fontSize: "18px" }}>นามสกุล</Typography>
                </Grid>
                <Grid xs={9}>
                  <TextField
                    id="lastname"
                    name="lastname"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: 5 }}>
                <Grid xs={3} sx={{ alignSelf: "center" }}>
                  <Typography sx={{ fontSize: "18px" }}>ตำแหน่ง</Typography>
                </Grid>
                <Grid xs={9}>
                  <Select
                    color="dark"
                    id="role"
                    name="role"
                    value={role}
                    onChange={changeRole}
                    fullWidth
                  >
                    <MenuItem value={1}>เจ้าหน้าที่โรงพยาบาล</MenuItem>
                    <MenuItem value={2}>ผู้อำนวยการโรงพยาบาล</MenuItem>
                    <MenuItem value={3}>เจ้าหน้าที่สาธารณสุข</MenuItem>
                    <MenuItem value={4}>ผู้ช่วยสาธารณสุข</MenuItem>
                    <MenuItem value={5}>ผู้อำนวยการสาธารณสุข</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Box
                textAlign="center"
                sx={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseEdit}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  sx={{ marginLeft: 3 }}
                  type="submit"
                >
                  ต่อไป
                </Button>
              </Box>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* ลบข้อมูลผู้ใช้ */}
      <Dialog
        open={openDelete}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff0c34", fontSize: 180 }} />
            </Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#ff0c34",
              }}
            >
              ยืนยันการลบข้อมูล
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="outlined"
                color="error"
                sx={{ borderRadius: 100 }}
                onClick={handleCloseDelete}
              >
                ย้อนกลับ
              </Button>
              <Button
                variant="outlined"
                color="success"
                sx={{ marginLeft: 3, borderRadius: 100 }}
                onClick={handleSubmitDelete}
              >
                ยืนยัน
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={openCreateSuccess} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }} textAlign="center">
            สร้างผู้ใช้สำเร็จ
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            ผู้ใช้งาน: {userHistory?.username}
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            รหัสผ่าน: {userHistory?.password}
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            ตำแหน่ง: {userHistory?.role}
          </Typography>
          <Typography sx={{ marginTop: 3, marginBottom: 3 }} textAlign="center">
            กรุณาบันทึกข้อมูลหรือบันทึกภาพเก็บไว้
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={7} sx={{ display: "flex", justifyContent: "right" }}>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `ผู้ใช้งาน:${userHistory?.username} รหัสผ่าน:  ${userHistory?.password}`
                  )
                }
              >
                คัดลอกข้อความ
              </Button>
            </Grid>
            <Grid item xs={5} sx={{ display: "flex", justifyContent: "left" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpenCreateSuccess(false)}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Users;
