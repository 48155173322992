import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RMIUploader } from "react-multiple-image-uploader";
import styles from "./styles/styles.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import moment from "moment/moment";
import provincesData from "../../../components/province.json";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import useAuth from "function/useAuth";
import MomentUtils from "@date-io/moment";
import "moment/locale/th";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PaidIcon from "@mui/icons-material/Paid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import config from "../../../config";
import api from "../../../api";
import { FileUploader } from "react-drag-drop-files";
import ClearIcon from "@mui/icons-material/Clear";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Slider from "react-slick";
import { Magnifier } from "react-image-magnifiers";

const DocumentsDetailExpal = () => {
  const [user, setUser] = useState();
  const [userCompany, setUserCompany] = useState(null);
  const { state } = useLocation();
  const params = state ? state.params : null;
  const genCode =
    params != null
      ? params
      : `Thanic-Expal-${moment().format("YYMMDDHHmmssSS")}`;
  const [isLoading, setIsLoading] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  // List Data
  const [debtCenterList, setDebtCenterList] = useState([]);
  const [prefixList, setPrefixList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const [type, setType] = useState("");
  const [finish, setFinish] = useState("");
  const [prefix, setPrefix] = useState(""); // คำนำหน้า
  const [firstName, setFirstName] = useState(""); // ชื่อจริง
  const [lastName, setLastName] = useState(""); // นามสกุล
  const [idCard, setIdCard] = useState(""); // นามสกุล
  const [nameCompany, setNameCompany] = useState(""); // ชื่อบริษัท
  const [debtCenter, setDebtCenter] = useState(""); // ศูนย์หนี้
  const [court, setCourt] = useState(""); // ศาล
  const [blackCaseNumber, setBlackCaseNumber] = useState(""); // เลขคดีดำเลขที่
  const [blackCaseYear, setBlackCaseYear] = useState(""); // เลขคดีดำเลขที่
  const [redCaseNumber, setRedCaseNumber] = useState(""); // เลขคดีแดงเลขที่
  const [redCaseYear, setRedCaseYear] = useState(""); // เลขคดีแดงเลขที่
  const [tambon, setTambon] = useState("");
  const [amphoe, setAmphoe] = useState("");
  const [province, setProvince] = useState("");
  const [tambonName, setTambonName] = useState("");
  const [amphoeName, setAmphoeName] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [fileNumber, setFileNumber] = useState(""); // เลขแฟ้ม
  const [dateStart, setDateStart] = useState(null); // วันที่รับเรื่อง
  const [deadline, setDeadline] = useState(null); // วันกำหนดส่ง
  const [propertyCode, setPropertyCode] = useState(""); // รหัสทรัพย์
  const [sector, setSector] = useState(""); // ภาค
  const [issueAWarranOfExecutionDate, setIssueAWarranOfExecutionDate] =
    useState(null); // วันขอออกหมายบังคับคดี
  const [executionOfficerDate, setExecutionOfficerDate] = useState(null); // วันนัดเจ้าหน้าที่บังคับคดี
  const [issueAnArrestWarrant, setIssueAnArrestWarrant] = useState(null); // วันขอออกหมายจับ
  const [debtorsAndDependentsDayOut, setDebtorsAndDependentsDayOut] =
    useState(null); // วันลูกหนี้และบริวารออก
  const [licenseDocument, setLicenseDocument] = useState(""); // เอกสารสิทธิ์
  const [licenseDocumentCode, setLicenseDocumentCode] = useState(""); // เอกสารสิทธิ์เลขที่
  const [caseStatus, setCaseStatus] = useState(""); // สถานะคดีปัจจุบัน
  const [note, setNote] = useState("");
  const [chooseMemberCompany, setChooseMemberCompany] = useState(""); // ไอดีผู้รับผิดชอบคดี
  const [chooseMemberCompanyName, setChooseMemberCompanyName] = useState(""); // ผู้รับผิดชอบคดี
  const [chooseMemberCompanyNow, setChooseMemberCompanyNow] = useState(""); // ไอดีผู้รับผิดชอบคดีปัจจุบัน
  const [chooseMemberCompanyNameNow, setChooseMemberCompanyNameNow] =
    useState(""); // ผู้รับผิดชอบคดีปัจจุบัน
  const [countDefendant, setCountDefendant] = useState(0); // จำนวนจำเลยร่วม
  const [coDefendantPrefix, setCoDefendantPrefix] = useState([]); // คำนำหน้าจำเลยร่วม
  const [coDefendantName, setCoDefendantName] = useState([]); // ชื่อจำเลยร่วม
  const [coDefendantLastName, setCoDefendantLastName] = useState([]); // นามสกุลจำเลยร่วม
  const [coDefendantAddress, setCoDefendantAddress] = useState([]); // ที่อยู่จำเลยร่วม
  const [coDefendantIdCard, setCoDefendantIdCard] = useState([]); // บัตรประชาชนจำเลยร่วม
  const [coDefendantStatus, setCoDefendantStatus] = useState([]); // สถานะจำเลยร่วม
  const [statusDefendant, setStatusDefendant] = useState([]);
  const [addressCoDefendant, setAddressCoDefendant] = useState([]);
  const [prefixDefendantAgent, setPrefixDefendantAgent] = useState([]); // ชื่อตัวแทนจำเลยร่วม
  const [nameDefendantAgent, setNameDefendantAgent] = useState([]); // ชื่อตัวแทนจำเลยร่วม
  const [lastNameDefendantAgent, setLastNameDefendantAgent] = useState([]); // นามสกุลตัวแทนจำเลยร่วม
  const [idCardDefendantAgent, setIdCardDefendantAgent] = useState([]); // รหัสบัตรประชาชนตัวแทนจำเลยร่วม
  const [addressDefendantAgent, setAddressDefendantAgent] = useState([]); // ที่อยู่ตัวแทนจำเลยร่วม
  const [statusDefendantAgent, setStatusDefendantAgent] = useState([]); // สถานะตัวแทนจำเลยร่วม
  const [courtCode, setCourtCode] = useState(""); // เลขคดีศาล
  const [companyCode, setCompanyCode] = useState(""); // รายละเอียดสถานที่
  const [locationDetail, setLocationDetail] = useState(""); // เลขคดีบริษัท
  const [titleDeed, setTitleDeed] = useState(""); // โฉนดที่ดิน
  const [landNumber, setLandNumber] = useState(""); // เลขที่ที่ดิน
  const [explorePage, setExplorePage] = useState(""); // หน้าสำรวจ
  const [googleMap, setGoogleMap] = useState(""); // googleMap
  const [bookingDate, setBookingDate] = useState(null); // วันนัด
  const [bookingTime, setBookingTime] = useState(null); // เวลานัด
  const [staffConnectionName, setStaffConnectionName] = useState(""); // ผู้ที่ติดต่อ
  const [staffConnectionPhone, setStaffConnectionPhone] = useState(""); // เบอร์ติดต่อผู้เกี่ยวข้อง
  const [phone, setPhone] = useState(""); // โทรศัพท์
  const [phone1, setPhone1] = useState(""); // โทรสาร
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [fileImg, setFileImg] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [courtDate, setCourtDate] = useState(null);
  const [courtTime, setCourtTime] = useState(null);
  const [courtList, setCourtList] = useState([]);
  const [countCourtEtc, setCountCourtEtc] = useState(0);
  // ขึ้นศาลเพิ่มเติม
  const [dateGoCourtEtc, setDateGoCourtEtc] = useState([]); // วันที่ขึ้นศาลครั้งนั้น
  const [timeGoCourtEtc, setTimeGoCourtEtc] = useState([]); // เวลาขึ้นศาลครั้งนั้น
  const [lawyerGoCourtEtc, setLawyerGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [lawyerNameGoCourtEtc, setLawyerNameGoCourtEtc] = useState([]); // ทนายผู้รับผิดชอบครั้งนั้น
  const [detailGoCourtEtc, setDetailGoCourtEtc] = useState([]); // รายละเอียดขึ้นศาลครั้งนั้น

  // Defendant
  const [defendantHouseNumber, setDefendantHouseNumber] = useState("");
  const [defendantGroup, setDefendantGroup] = useState("");
  const [defendantRoad, setDefendantRoad] = useState("");
  const [defendantSoi, setDefendantSoi] = useState("");
  const [defendantAddress, setDefendantAddress] = useState("");
  const [defendantTambon, setDefendantTambon] = useState("");
  const [defendantAmphoe, setDefendantAmphoe] = useState("");
  const [defendantProvince, setDefendantProvince] = useState("");
  const [defendantTambonName, setDefendantTambonName] = useState("");
  const [defendantAmphoeName, setDefendantAmphoeName] = useState("");
  const [defendantProvinceName, setDefendantProvinceName] = useState("");
  const [defendantZipcode, setDefendantZipcode] = useState("");

  // Place
  const [placeHouseNumber, setPlaceHouseNumber] = useState("");
  const [placeGroup, setPlaceGroup] = useState("");
  const [placeRoad, setPlaceRoad] = useState("");
  const [placeSoi, setPlaceSoi] = useState("");
  const [placeAddress, setPlaceAddress] = useState("");
  const [placeTambon, setPlaceTambon] = useState("");
  const [placeAmphoe, setPlaceAmphoe] = useState("");
  const [placeProvince, setPlaceProvince] = useState("");
  const [placeTambonName, setPlaceTambonName] = useState("");
  const [placeAmphoeName, setPlaceAmphoeName] = useState("");
  const [placeProvinceName, setPlaceProvinceName] = useState("");
  const [placeZipcode, setPlaceZipcode] = useState("");
  const [openSetNotifition, setOpenSetNotifition] = useState(false);
  const [openCloseCase, setOpenCloseCase] = useState(false);

  const defaultCodeCompany = `TH${fileNumber}`;

  const [typeDocsUpload, setTypeDocsUpload] = useState("");
  const [topicDocsUpload, setTopicDocsUpload] = useState("");
  //   const [typeDocsList, setTypeDocsList] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileImageUpload, setFileImageUpload] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fileData, setFileData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [images, setImages] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [rowsImages, setRowsImages] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const fileTypes = ["PDF"];
  const fileImageTypes = ["JPEG", "PNG", "JPG"];

  const [visible, setVisible] = useState(false);
  const handleSetVisible = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };
  const onUpload = (data) => {
    console.log("Upload files", data);
  };
  const onSelect = (data) => {
    console.log("Select files", data);
  };
  const onRemove = (id) => {
    console.log("Remove image id", id);
  };

  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  const handleUploadImageChange = (file) => {
    setFileImageUpload(file);
    console.log(file);
  };

  const columns = [
    { id: "order", label: "ลำดับ", minWidth: 100 },
    // { id: "list", label: "รายการ", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "date", label: "วันที่ยื่นเอกสาร", minWidth: 100 },
    {
      id: "file",
      label: "ไฟล์เอกสาร",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleDownload(row)} disabled={!row.path}>
            <PictureAsPdfIcon color={row.path ? "error" : "inherit"} />
          </IconButton>
        </>
      ),
    },
  ];

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  useAuth();

  function getCourtList() {
    axios
      .get(`${config.mainAPI}/court-list`)
      .then((response) => {
        setCourtList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getUserCompany() {
    axios
      .get(`${config.mainAPI}/users-company`)
      .then((response) => {
        setUserCompany(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlPrefix() {
    axios
      .get(`${config.mainAPI}/prefix-list`)
      .then((response) => {
        setPrefixList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDdlDebtCenter() {
    api
      .get(`${config.mainAPI}/debtCenter`)
      .then((response) => {
        setDebtCenterList(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleSumbitFileUpload = async () => {
    if (fileUpload) {
      console.log("fileUpload", fileUpload);
      const formData = new FormData();
      formData.append("fileUpload", fileUpload);
      formData.append("fileName", fileUpload.name);
      formData.append("topic", topicDocsUpload);
      formData.append("type", typeDocsUpload);
      formData.append("type_docs", "evication");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileUpload.name} Success`);

        getListFile();
        setFileUpload(null);
        setTypeDocsUpload("");
        setTopicDocsUpload("");
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUpload.name} Failure`);
      }
    }
  };

  const handleSumbitFileImageUpload = async () => {
    if (fileImageUpload) {
      console.log("fileImageUpload", fileImageUpload);
      const formData = new FormData();
      formData.append("fileImageUpload", fileImageUpload);
      formData.append("fileName", fileImageUpload.name);
      formData.append("topic", "imagePlace");
      formData.append("type", "image");
      formData.append("type_docs", "evication");
      formData.append("user", user.username);
      formData.append("id", params);
      try {
        const res = await axios.post(
          `${config.mainAPI}/upload-file-full`,
          formData
        );
        saveActivityLog(`Upload File:${fileImageUpload.name} Success`);
        getListFile();
        setFileImageUpload(null);
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileImageUpload.name} Failure`);
      }
    }
  };

  function handleClearFileImageUpload() {
    setFileImageUpload(null);
  }

  function handleClearFileUpload() {
    setFileUpload(null);
    setTypeDocsUpload("");
    setTopicDocsUpload("");
  }

  function createData(order, type, date, path) {
    const formattedDate = moment(date)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY");
    return {
      order,
      // list,
      type,
      date: date ? formattedDate : null,
      path,
    };
  }

  function getListFile() {
    axios
      .get(`${config.mainAPI}/file-list/${params}`)
      .then((response) => {
        console.log("value filelist", response.data.data);
        setFileList(response.data.data);
        const value = response.data.data;
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              // item.file_topic,
              item.file_type,
              item.created_at,
              item.file_path,
              item.update_at
            )
          )
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function createDataImages(order, uuid, list, type, date, work_by, path, main) {
    const formattedDate = date
      ? moment(date).locale("th").add(543, "year").format("DD/MM/YYYY")
      : null;
    const newList = list ? list : "-";
    return {
      order,
      uuid,
      // list: newList,
      type,
      date: formattedDate,
      work_by,
      path,
      main,
    };
  }

  function getListFileImage() {
    axios
      .get(`${config.mainAPI}/getImagesFileById/${params}`)
      .then((response) => {
        console.log("value imageslist", response.data.data);
        const value = response.data.data;
        if (value) {
          setImageList(value);
          setRowsImages(
            value.map((item, index) =>
              createDataImages(
                index + 1,
                item.file_uuid,
                item.file_topic,
                item.file_type,
                item.created_at,
                item.created_by,
                item.file_path,
                item.file_main,
                item.update_at
              )
            )
          );

          // setImages
          const newArrays = [];
          value.map((item, index) => {
            const pathImage = `${config.mainAPI}/getImagesFromPathToUrl/${item.file_type_docs}/${item.file_name}`;
            newArrays.push(pathImage);
          });
          console.log('newArrays', newArrays);
          setImages(newArrays);
          console.log('images state:', images); // Check what's in the images state
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleDeleteFile = (row) => {
    const payload = {
      fileName: row.path,
      uuid: row.uuid,
      id: params,
      type: "evication",
    };
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/deleteFileByPath`, {
        ...payload,
      })
      .then(function (response) {
        console.log("response"), response;
        getListFile();
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleBack = () => {
    navigate("/expal");
  };

  // const handleProvinceChange = (event) => {
  //   if (event) {
  //     const value = parseInt(event.target.dataset.value);
  //     const defendantProvinceName = event.currentTarget.getAttribute("name");
  //     setDefendantProvince(value);
  //     setDefendantProvinceName(defendantProvinceName);
  //     setDefendantAmphoe("");
  //     setDefendantTambon("");
  //   }
  // };

  // const handleAmphoeChange = (event) => {
  //   if (event) {
  //     const value = parseInt(event.target.dataset.value);
  //     const defendantAmphoeName = event.currentTarget.getAttribute("name");
  //     setDefendantAmphoe(value);
  //     setDefendantAmphoeName(defendantAmphoeName);
  //     setDefendantTambon("");
  //   }
  // };

  // const handleTambonChange = (event) => {
  //   if (event) {
  //     const value = parseInt(event.target.dataset.value);
  //     const defendantTambonName = event.currentTarget.getAttribute("name");
  //     const defendantTambonZipcode = event.currentTarget.getAttribute("code");
  //     setDefendantZipcode(defendantTambonZipcode);
  //     setDefendantTambon(value);
  //     setDefendantTambonName(defendantTambonName);
  //   }
  // };

  // const getAmphoesByProvinceId = (provinceId) => {
  //   if (provinceId) {
  //     return provincesData.find((province) => province.id === provinceId)
  //       .amphure;
  //   }
  // };

  // const getTambonsByAmphoeId = (amphoeId) => {
  //   if (amphoeId) {
  //     return provincesData
  //       .flatMap((province) => province.amphure)
  //       .find((amphoe) => amphoe.id === amphoeId).tambon;
  //   }
  // };

  const handleProvinceChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const placeProvinceName = event.currentTarget.getAttribute("name");
      setPlaceProvince(value);
      setPlaceProvinceName(placeProvinceName);
      setPlaceAmphoe("");
      setPlaceTambon("");
    }
  };

  const handleAmphoeChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const placeAmphoeName = event.currentTarget.getAttribute("name");
      setPlaceAmphoe(value);
      setPlaceAmphoeName(placeAmphoeName);
      setPlaceTambon("");
    }
  };

  const handleTambonChange = (event) => {
    if (event) {
      const value = parseInt(event.target.dataset.value);
      const placeTambonName = event.currentTarget.getAttribute("name");
      const placeTambonZipcode = event.currentTarget.getAttribute("code");
      setPlaceZipcode(placeTambonZipcode);
      setPlaceTambon(value);
      setPlaceTambonName(placeTambonName);
    }
  };

  const getAmphoesByProvinceId = (provinceId) => {
    if (provinceId) {
      return provincesData.find((province) => province.id === provinceId)
        .amphure;
    }
  };

  const getTambonsByAmphoeId = (amphoeId) => {
    if (amphoeId) {
      return provincesData
        .flatMap((province) => province.amphure)
        .find((amphoe) => amphoe.id === amphoeId).tambon;
    }
  };

  const disabledHours = () => {
    const disabledHoursArray = [];
    for (let i = 0; i < 8; i++) {
      disabledHoursArray.push(i);
    }
    for (let i = 19; i <= 23; i++) {
      disabledHoursArray.push(i);
    }
    return disabledHoursArray;
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleDownload = (row) => {
    const file_path = row.path;
    const fileName = path.basename(file_path);
    saveActivityLog(`Click download file: ${fileName}`);
    const download_url = `${config.mainAPI}/download-file?path=${file_path}`;
    window.location.href = download_url;
  };

  const deleteFile = () => {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/delete-file`, {
        filePath,
      })
      .then(function (response) {
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    const caseData = params != null ? "edit" : "add";
    const payload = {
      caseData: caseData,
      genCode: genCode,
      finish: finish,
      type: type,
      prefix: prefix,
      firstName: firstName,
      lastName: lastName,
      idCard: idCard,
      nameCompany: nameCompany,
      debtCenter: debtCenter,
      coDefendant: coDefendant,
      countDefendant: parseInt(countDefendant),
      defendantAgent: defendantAgent,
      court: court,
      courtDate: courtDate,
      courtTime: courtTime,
      countCourtEtc: parseInt(countCourtEtc),
      goCourtEtc: goCourtEtc,
      blackCaseNumber: blackCaseNumber,
      redCaseNumber: redCaseNumber,
      blackCaseYear: blackCaseYear,
      redCaseYear: redCaseYear,
      tambon: tambon,
      amphoe: amphoe,
      province: province,
      tambonName: tambonName,
      amphoeName: amphoeName,
      provinceName: provinceName,
      zipcode: zipcode,
      defendantHouseNumber: defendantHouseNumber,
      defendantGroup: defendantGroup,
      defendantRoad: defendantRoad,
      defendantSoi: defendantSoi,
      defendantAddress: defendantAddress,
      defendantTambonName: defendantTambonName,
      defendantAmphoeName: defendantAmphoeName,
      defendantProvinceName: defendantProvinceName,
      defendantTambon: defendantTambon,
      defendantAmphoe: defendantAmphoe,
      defendantProvince: defendantProvince,
      defendantZipcode: defendantZipcode,
      placeHouseNumber: placeHouseNumber,
      placeGroup: placeGroup,
      placeRoad: placeRoad,
      placeSoi: placeSoi,
      placeAddress: placeAddress,
      placeTambonName: placeTambonName,
      placeAmphoeName: placeAmphoeName,
      placeProvinceName: placeProvinceName,
      placeTambon: placeTambon,
      placeAmphoe: placeAmphoe,
      placeProvince: placeProvince,
      placeZipcode: placeZipcode,
      phone: phone,
      phone1: phone1,
      fileNumber: fileNumber,
      // dateStart: dateStart,
      dateStart: dateStart,
      deadline: deadline,
      propertyCode: propertyCode,
      sector: sector,
      googleMap: googleMap,
      issueAWarranOfExecutionDate: issueAWarranOfExecutionDate,
      executionOfficerDate: executionOfficerDate,
      issueAnArrestWarrant: issueAnArrestWarrant,
      debtorsAndDependentsDayOut: debtorsAndDependentsDayOut,
      licenseDocument: licenseDocument,
      licenseDocumentCode: licenseDocumentCode,
      caseStatus: caseStatus,
      note: note,
      bookingDate: bookingDate,
      bookingTime: bookingTime,
      staffConnectionName: staffConnectionName,
      staffConnectionPhone: staffConnectionPhone,
      chooseMemberCompany: chooseMemberCompany,
      chooseMemberCompanyName: chooseMemberCompanyName,
      coDefendant: coDefendant,
      courtCode: courtCode,
      companyCode: companyCode,
      titleDeed: titleDeed,
      locationDetail: locationDetail,
      landNumber: landNumber,
      explorePage: explorePage,
      user: user?.username,
      // countGoCourtDB:
      //     caseData == "edit" ? countCourtEtc + 1 : courtDate ? 1 : 0,
    };
    console.log("payload", payload);
    // axios
    //   .post("${config.mainAPI}/expal", {
    //     ...payload,
    //   })
    //   .then(function (response) {
    //     setOpenSuccess(true);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const handleFileChange = (event) => {
    const fileData = event.target.files[0];
    setFile(fileData);
    setFile1(fileData);
  };

  const uploadFile = async () => {
    if (file) {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      try {
        const res = await axios.post(`${config.mainAPI}/upload-file`, formData);
        console.log("eoeo", res);
        setFileName(res.data.name);
        setFilePath(res.data.path);

        setFileImg(res.data.pathImg);
        setCheckFile(true);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleWorkByChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompany(workById);
    setChooseMemberCompanyName(workByName);
  };

  const handleWorkByNowChange = (event) => {
    const value = parseInt(event.target.dataset.value);
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    setChooseMemberCompanyNow(workById);
    setChooseMemberCompanyNameNow(workByName);
  };

  const handleCoDefendantStatusChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantStatus((prevStatusDefendant) => {
      const newStatusDefendant = [...prevStatusDefendant];
      newStatusDefendant[index] = value;
      return newStatusDefendant;
    });
  };

  const handleCoDefendantPrefixChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantPrefix((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantLastNameChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantLastName((prevStatusDefendant) => {
      const newCoDefendant = [...prevStatusDefendant];
      newCoDefendant[index] = value;
      return newCoDefendant;
    });
  };

  const handleCoDefendantAddressChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantAddress((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  const handleCoDefendantIdCardChange = (event, index) => {
    const { value } = event.target;
    setCoDefendantIdCard((prevStatusDefendant) => {
      const newAddressCoDefendant = [...prevStatusDefendant];
      newAddressCoDefendant[index] = value;
      return newAddressCoDefendant;
    });
  };

  const handlePrefixDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setPrefixDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleLastNameDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setLastNameDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleIdCardDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setIdCardDefendantAgent((prevStatusDefendant) => {
      const newNameDefendantAgent = [...prevStatusDefendant];
      newNameDefendantAgent[index] = value;
      return newNameDefendantAgent;
    });
  };

  const handleAddressDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setAddressDefendantAgent((prevStatusDefendant) => {
      const newAddressDefendantAgent = [...prevStatusDefendant];
      newAddressDefendantAgent[index] = value;
      return newAddressDefendantAgent;
    });
  };

  const handleStatusDefendantAgentChange = (event, index) => {
    const { value } = event.target;
    setStatusDefendantAgent((prevStatusDefendant) => {
      const newStatusDefendantAgent = [...prevStatusDefendant];
      newStatusDefendantAgent[index] = value;
      return newStatusDefendantAgent;
    });
  };

  const coDefendant = coDefendantName.map((item, index) => ({
    prefix: coDefendantPrefix[index],
    name: item,
    lastname: coDefendantLastName[index],
    status: coDefendantStatus[index],
    address: coDefendantAddress[index],
    idCard: coDefendantIdCard[index],
    role: `จำเลยร่วมที่${[index + 1]}`,
    order: index + 1,
  }));

  const defendantAgent = nameDefendantAgent.map((item, index) => ({
    prefix: prefixDefendantAgent[index] || null,
    name: item || null,
    lastname: lastNameDefendantAgent[index] || null,
    address: addressDefendantAgent[index] || null,
    idCard: idCardDefendantAgent[index] || null,
    status: statusDefendantAgent[index] || null,
    role: `ตัวแทนจำเลยร่วมที่${[index + 1]}` || null,
    order: index + 1,
  }));

  const listCoDefendantStatus = [
    { id: 0, value: "มีชีวิตอยู่" },
    { id: 1, value: "เสียชีวิต" },
  ];

  const listStatusDead = [
    { id: 0, value: "ผู้จัดการมรดก" },
    { id: 1, value: "คู่สมรส" },
    { id: 2, value: "บิดา-มารดา" },
  ];

  // ? moment(decree, "DD/MM/YYYY").format("YYYY-MM-DD")

  const getDataForm = () => {
    setIsLoading(true);

    axios
      .get(`${config.mainAPI}/expal/${params}`)
      .then((response) => {
        console.log("response.data.data", response.data.data);

        const value = response.data.data[0];
        setDateStart(value.date_start);
        setDeadline(value.date_send);
        setType(value.type);
        if (value.type == "บุคคล") {
          setFileNumber(value.file_number);
          setPrefix(value.defendant_prefix);
          setFirstName(value.defendant_firstname);
          setLastName(value.defendant_lastname);
          setIdCard(value.defendant_idcard);
        } else if (value.type == "นิติบุคคล") {
          setNameCompany(value.defendant_fullname);
        }
        setTambon(parseInt(value.defendant_tambon));
        setTambonName(value.defendant_tambon_show);
        setAmphoe(parseInt(value.defendant_amphoe));
        setAmphoeName(value.defendant_amphoe_show);
        setProvince(parseInt(value.defendant_province));
        setProvinceName(value.defendant_province_show);
        if (value.defendant_province) {
          console.log("value.defendant_province", value.defendant_province);
          getAmphoesByProvinceId(parseInt(value.defendant_province));
        }
        if (value.defendant_amphoe) {
          console.log("value.defendant_amphoe", value.defendant_amphoe);
          getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        }
        setZipcode(parseInt(value.defendant_zipcode));
        setDebtCenter(value.debt_center);
        setCourt(value.court);

        setCourtDate(value.court_date);
        setCourtTime(
          value.court_time ? moment(value.court_time, "HH:mm") : null
        );
        setCountCourtEtc(value.court_count - 1);
        console.log("value.court_count", value.court_count);
        console.log("value.goCourtEtc", value.goCourtEtc);
        if (value.court_count > 0 && value.goCourtEtc) {
          value.goCourtEtc.forEach((item, index) => {
            console.log("index", index);
            // if (index > 0) {
            setDateGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_date;
              return updatedState;
            });

            setTimeGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_time;
              return updatedState;
            });

            setLawyerGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by;
              return updatedState;
            });

            setLawyerNameGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_work_by_name;
              return updatedState;
            });

            setDetailGoCourtEtc((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.go_court_detail;
              return updatedState;
            });
            // }
          });
        }

        setPhone(value.defendant_phone);
        setPhone1(value.defendant_phone1);
        setGoogleMap(value.google_map);
        setPropertyCode(value.property_code);
        setSector(value.sector);
        setBlackCaseNumber(value.black_case_number);
        setBlackCaseYear(value.black_case_year);
        setRedCaseNumber(value.red_case_number);
        setRedCaseYear(value.red_case_year);
        setIssueAWarranOfExecutionDate(value.issue_warran_of_execution_date);
        setIssueAnArrestWarrant(value.issue_arrest_warrant);
        setLicenseDocument(value.license_document);
        setLicenseDocumentCode(value.license_document_code);
        setCaseStatus(value.case_status);
        setExecutionOfficerDate(value.execution_officer_date);
        setIssueAnArrestWarrant(value.issue_arrest_warrant);
        setDebtorsAndDependentsDayOut(value.debtors_and_dependents_day_out);
        setLocationDetail(value.location_detail);
        setTitleDeed(value.title_deed);
        setLandNumber(value.land_number);
        setExplorePage(value.explore_page);
        setBookingTime(value.time_meet);
        setBookingDate(value.date_meet);
        setNote(value.note);
        setChooseMemberCompany(value.work_by);
        setChooseMemberCompanyName(value.work_by_name);
        setCountDefendant(value.codefendant_count);
        if (value.codefendant_count > 0) {
          value.coDefendant.forEach((item, index) => {
            setCoDefendantPrefix((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_prefix;
              return updatedState;
            });

            setCoDefendantName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_firstname;
              return updatedState;
            });

            setCoDefendantLastName((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_lastname;
              return updatedState;
            });

            setCoDefendantAddress((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_address;
              return updatedState;
            });

            setCoDefendantIdCard((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_idcard;
              return updatedState;
            });

            setCoDefendantStatus((prevState) => {
              const updatedState = [...prevState];
              updatedState[index] = item.codefendant_status;
              return updatedState;
            });
          });
        }
        // setPrefixDefendantAgent(value.);
        // setNameDefendantAgent(value.);
        // setLastNameDefendantAgent(value.);
        // setIdCardDefendantAgent(value.);
        // setAddressDefendantAgent(value.);
        // setStatusDefendantAgent(value.);
        setCompanyCode(value.company_code);
        setCourtCode(value.company_code);
        setStaffConnectionName(value.admin_meet);
        setStaffConnectionPhone(value.phone_meet);

        // setDefendantHouseNumber(value.defendant_house_number); // (value.defendantHouseNumber) บ้านเลขที่
        // setDefendantGroup(value.defendant_group); // หมู่
        // setDefendantRoad(value.defendant_road); // ถนน
        // setDefendantSoi(value.defendant_soi); // ซอย
        // setDefendantAddress(value.defendant_address);
        // setDefendantTambon(parseInt(value.defendant_tambon));
        // setDefendantTambonName(value.defendant_tambon_show);
        // setDefendantAmphoe(parseInt(value.defendant_amphoe));
        // setDefendantAmphoeName(value.defendant_amphoe_show);
        // setDefendantProvince(parseInt(value.defendant_province));
        // setDefendantProvinceName(value.defendant_province_show);
        // if (value.defendant_province) {
        //   console.log("value.defendant_province", value.defendant_province);
        //   getAmphoesByProvinceId(parseInt(value.defendant_province));
        // }
        // if (value.defendant_amphoe) {
        //   console.log("value.defendant_amphoe", value.defendant_amphoe);
        //   getTambonsByAmphoeId(parseInt(value.defendant_amphoe));
        // }
        // setDefendantZipcode(parseInt(value.defendant_zipcode));

        setPlaceHouseNumber(value.place_house_number); // (value.placeHouseNumber) บ้านเลขที่
        setPlaceGroup(value.place_group); // หมู่
        setPlaceRoad(value.place_road); // ถนน
        setPlaceSoi(value.place_soi); // ซอย
        setPlaceAddress(value.place_address);
        setPlaceTambon(parseInt(value.place_tambon));
        setPlaceTambonName(value.place_tambon_show);
        setPlaceAmphoe(parseInt(value.place_amphoe));
        setPlaceAmphoeName(value.place_amphoe_show);
        setPlaceProvince(parseInt(value.place_province));
        setPlaceProvinceName(value.place_province_show);
        if (value.place_province) {
          // console.log("value.place_province", value.place_province);
          getAmphoesByProvinceId(parseInt(value.place_province));
        }
        if (value.place_amphoe) {
          // console.log("value.place_amphoe", value.place_amphoe);
          getTambonsByAmphoeId(parseInt(value.place_amphoe));
        }
        setPlaceZipcode(parseInt(value.place_zipcode));

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCloseFailure = () => {
    setOpenFailure(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate("/expal");
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    console.log("datana", JSON.parse(userData));
    setUser(JSON.parse(userData));
    getUserCompany();
    getDdlPrefix();
    getDdlDebtCenter();
    getCourtList();
    if (params) {
      getDataForm();
      getListFile();
      getListFileImage();
    } else {
      // navigate("/expal");
    }
  }, []);
  const navigate = useNavigate();
  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setData(christDate);
  };

  const handleDateGoCourtEtcChangeByIndex = (
    christDate,
    buddhistDate,
    index
  ) => {
    setDateGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = christDate;
      return data;
    });
  };

  const handleTimeGoCourtEtcChange = (date, index) => {
    const formattedDate = date;
    setTimeGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = formattedDate;
      return data;
    });
  };

  const updateCountCourtEtc = (event) => {
    setCountCourtEtc(event);
    console.log("CountCourtEtc", event);

    // const handleWorkByChange = (event) => {
    //   const value = parseInt(event.target.dataset.value);
    //   const workByName = event.currentTarget.getAttribute("name");
    //   const workById = event.currentTarget.getAttribute("id");
    //   setChooseMemberCompany(workById);
    //   setChooseMemberCompanyName(workByName);
    // };

    // Splice data from arrays if their lengths are greater than countCourtEtc
    // อนาคตต้องมีการดักว่าต้องกรอกนะ
    if (
      dateGoCourtEtc.length > event ||
      timeGoCourtEtc.length > event ||
      lawyerGoCourtEtc.length > event ||
      detailGoCourtEtc.length > event
    ) {
      setDateGoCourtEtc((prevDateGoCourtEtc) =>
        prevDateGoCourtEtc.slice(0, event)
      );
      setTimeGoCourtEtc((prevTimeGoCourtEtc) =>
        prevTimeGoCourtEtc.slice(0, event)
      );
      setLawyerGoCourtEtc((prevLawyerGoCourtEtc) =>
        prevLawyerGoCourtEtc.slice(0, event)
      );
      setDetailGoCourtEtc((prevDetailGoCourtEtc) =>
        prevDetailGoCourtEtc.slice(0, event)
      );
    }
  };

  const handleLawyerGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    const workByName = event.currentTarget.getAttribute("name");
    const workById = event.currentTarget.getAttribute("id");
    console.log("workByName", workByName);
    console.log("workById", workById);
    setLawyerGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workById;
      return data;
    });
    setLawyerNameGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = workByName;
      return data;
    });
  };

  const handleDetailGoCourtEtcChange = (event, index) => {
    const { value } = event.target;
    setDetailGoCourtEtc((prevStatusDefendant) => {
      const data = [...prevStatusDefendant];
      data[index] = value;
      return data;
    });
  };

  const goCourtEtc = dateGoCourtEtc.map((item, index) => ({
    date: item ? moment(item).format("YYYY-MM-DD") : null,
    time: timeGoCourtEtc[index] ? timeGoCourtEtc[index] : null,
    lawyer: lawyerGoCourtEtc[index] ? lawyerGoCourtEtc[index] : null,
    lawyerName: lawyerNameGoCourtEtc[index]
      ? lawyerNameGoCourtEtc[index]
      : null,
    detail: detailGoCourtEtc[index] ? detailGoCourtEtc[index] : null,
    round: index + 1,
  }));

  const handleSetNotification = () => {
    setOpenSetNotifition(true);
  };
  const handleCloseSetNotification = () => {
    setOpenSetNotifition(false);
  };

  function updateStatusFinish() {
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/document-success`, {
        genCode: genCode,
        type: "expal",
      })
      .then(function (response) {
        setIsLoading(false);
        setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setOpenFailure(true);
      });
  }

  const handleSetOpenCloseCase = () => {
    setOpenCloseCase(true);
  };

  const handleCloseSetOpenCloseCase = () => {
    setOpenCloseCase(false);
  };

  const handleHistoryWithDraw = () => {
    const id = params;
    navigate("/draw", { state: { params: id } });
  };

  const handleHistoryGetMoney = () => {
    const id = params;
    navigate("/money", { state: { params: id } });
  };

  const dataSources = [
    {
      id: 1,
      dataURL: "https://picsum.photos/seed/1/600",
    },
    {
      id: 2,
      dataURL: "https://picsum.photos/seed/2/600",
    },
    {
      id: 3,
      dataURL: "https://picsum.photos/seed/3/600",
    },
    {
      id: 4,
      dataURL: "https://picsum.photos/seed/4/600",
    },
    {
      id: 5,
      dataURL: "https://picsum.photos/seed/5/600",
    },
    {
      id: 6,
      dataURL: "https://picsum.photos/seed/6/600",
    },
    {
      id: 7,
      dataURL: "https://picsum.photos/seed/7/600",
    },
    {
      id: 8,
      dataURL: "https://picsum.photos/seed/8/600",
    },
    {
      id: 9,
      dataURL: "https://picsum.photos/seed/9/600",
    },
    {
      id: 10,
      dataURL: "https://picsum.photos/seed/10/600",
    },
  ];

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "20px" }}
        >
          ข้อมูลคดีขับไล่
        </Typography>
        {/* <Card>
          {params ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: 3,
                marginTop: 3,
                float: "right",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleSetNotification}
                startIcon={<NotificationsActiveIcon />}
              >
                การแจ้งเตือน
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleHistoryWithDraw}
                startIcon={<RequestQuoteIcon />}
              >
                ประวัติการเบิกเงิน
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="dark"
                onClick={handleHistoryGetMoney}
                startIcon={<PaidIcon />}
              >
                ประวัติการได้รับค่าจ้าง
              </Button>
              <Button
                variant="contained"
                sx={{
                  marginRight: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                color="success"
                onClick={handleSetOpenCloseCase}
                startIcon={<DoneAllIcon />}
              >
                ปิดคดี
              </Button>
            </Box>
          ) : null}
        </Card> */}
        <Box sx={{ padding: "30px" }}>
          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลลูกหนี้"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                {/* <Grid item xs={12} sm={6} sx={{mb:2}}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ประเภท:
                    <span style={{ marginLeft: 5 }}>{type}</span>
                  </Typography>
                </Grid> */}
                {type == "นิติบุคคล" ? (
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      จำเลย:
                      <span style={{ marginLeft: 5 }}>{nameCompany ? nameCompany : '-'}</span>
                    </Typography>
                  </Grid>
                ) : type == "บุคคล" ? (
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      จำเลย:
                      <span style={{ marginLeft: 5 }}>
                        {firstName ? prefix + firstName + " " + lastName : '-'}
                      </span>
                    </Typography>
                  </Grid>
                ) : null}

                {type == "บุคคล" ? (
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      บัตรประชาชน:
                      <span style={{ marginLeft: 5 }}>{idCard ? idCard : '-'}</span>
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    โทรสาร:
                    <span style={{ marginLeft: 5 }}>{phone1 ? phone1 : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    โทรศัพท์:
                    <span style={{ marginLeft: 5 }}>{phone ? phone : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader sx={{ backgroundColor: "#181414" }} title="ข้อมูลคดี" />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    รหัสทรัพย์:{" "}
                    <span style={{ marginLeft: 5 }}>{propertyCode ? propertyCode : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ภาค: <span style={{ marginLeft: 5 }}>{sector ? sector : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ศูนย์หนี้:{" "}
                    <span style={{ marginLeft: 5 }}>{debtCenter ? debtCenter : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีดำ:
                    <span style={{ marginLeft: 5 }}>
                      {blackCaseNumber | blackCaseYear ? blackCaseNumber + "/" + blackCaseYear : '-'}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีแดง:
                    <span style={{ marginLeft: 5 }}>
                      {redCaseNumber | redCaseYear ? redCaseNumber + "/" + redCaseYear : '-'}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ศาล:<span style={{ marginLeft: 5 }}>{court}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีศาล:
                    <span style={{ marginLeft: 5 }}>{courtCode}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันขึ้นศาล:
                    <span style={{ marginLeft: 5 }}>
                      {courtDate
                        ? moment(courtDate)
                          .locale("th")
                          .add(543, "year")
                          .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เวลาขึ้นศาล:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {" "}
                      {courtTime
                        ? moment(courtTime, "HH:mm:ss").format("HH:mm")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันขอออกหมายบังคับคดี:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {" "}
                      {courtDate
                        ? moment(issueAWarranOfExecutionDate)
                          .locale("th")
                          .add(543, "year")
                          .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันนัดเจ้าหน้าที่บังคับคดี:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {courtDate
                        ? moment(executionOfficerDate)
                          .locale("th")
                          .add(543, "year")
                          .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันขอออกหมายจับ:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {courtDate
                        ? moment(issueAnArrestWarrant)
                          .locale("th")
                          .add(543, "year")
                          .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันลูกหนี้และบริวารออก:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {courtDate
                        ? moment(debtorsAndDependentsDayOut)
                          .locale("th")
                          .add(543, "year")
                          .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลทรัพย์"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เอกสารสิทธิ์:{" "}
                    <span style={{ marginLeft: 5 }}>{licenseDocument ? licenseDocument : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เอกสารสิทธิ์เลขที่:{" "}
                    <span style={{ marginLeft: 5 }}>{licenseDocumentCode ? licenseDocumentCode : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ทรัพย์เลขที่:{" "}
                    <span style={{ marginLeft: 5 }}>{placeHouseNumber ? placeHouseNumber : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    หมู่: <span style={{ marginLeft: 5 }}>{placeGroup ? placeGroup : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ถนน: <span style={{ marginLeft: 5 }}>{placeRoad ? placeRoad : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ซอย: <span style={{ marginLeft: 5 }}>{placeSoi ? placeSoi : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container pacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ใกล้เคียง:{" "}
                    <span style={{ marginLeft: 5 }}>{placeAddress ? placeAddress : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เขต/ตำบล:{" "}
                    <span style={{ marginLeft: 5 }}>{tambonName ? tambonName : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    แขวง/อำเภอ:{" "}
                    <span style={{ marginLeft: 5 }}>{amphoeName ? amphoeName : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    จังหวัด:{" "}
                    <span style={{ marginLeft: 5 }}>{provinceName ? provinceName : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    รหัสไปรษณี:{" "}
                    <span style={{ marginLeft: 5 }}>{placeZipcode ? placeZipcode : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "16px" }}>
                    รายละเอียดสถานที่:{" "}
                    <span style={{ marginLeft: 5 }}>{locationDetail ? locationDetail : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    โฉนดที่ดินเลขที่:{" "}
                    <span style={{ marginLeft: 5 }}>{titleDeed ? titleDeed : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขที่ที่ดิน:{" "}
                    <span style={{ marginLeft: 5 }}>{landNumber ? landNumber : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    หน้าสำรวจ:{" "}
                    <span style={{ marginLeft: 5 }}>{explorePage ? explorePage : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    Google Map:{" "}
                    <span style={{ marginLeft: 5 }}>{googleMap ? googleMap : '-'}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    หมายเหตุ:{" "}
                    <span style={{ marginLeft: 5 }}>{note ? note : "-"}</span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันนัด:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {courtDate
                        ? moment(bookingDate)
                          .locale("th")
                          .add(543, "year")
                          .format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เวลานัด:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {" "}
                      {courtTime
                        ? moment(bookingTime, "HH:mm:ss").format("HH:mm")
                        : "-"}
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เจ้าหน้าที่ตำรวจประจำท้องที่:{" "}
                    <span style={{ marginLeft: 5 }}>{staffConnectionName ? staffConnectionName : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เบอร์ติดต่อ:{" "}
                    <span style={{ marginLeft: 5 }}>
                      {staffConnectionPhone ? staffConnectionPhone : '-'}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="รูปภาพข้อมูลทรัพย์"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  {images.length > 0 ? (
                    <>
                      <Slider {...settings}>
                        {images.map((item, index) => (
                          <div key={index}>
                            <Magnifier
                              imageSrc={item} // Directly use the URL here
                              imageAlt={`Image ${index}`}
                              style={{ width: '100%' }}
                              mgShape='square'
                              mgShowOverflow={false}
                            />
                          </div>
                        ))}
                      </Slider>
                    </>
                  ) : (
                    <Typography sx={{ fontSize: "16px", textAlign: 'center', marginTop: 3 }}>ไม่พบรูปภาพ</Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>



          <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="รายการเอกสารในสำนวนคดี"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "30px",
                    }}
                  >
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align="center"
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredRows
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => (
                              <TableRow key={row.order}>
                                {columns.map((column) => (
                                  <TableCell key={column.id} align="center">
                                    {column.render
                                      ? column.render(row)
                                      : row[column.id]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      /> */}
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {user && user.role != "bank" ? (
            <Card sx={{ mb: 3 }}>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลบริษัท"
              />
              <CardContent sx={{ border: "0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขแฟ้ม:
                      <span style={{ marginLeft: 5 }}>
                        {fileNumber ? fileNumber : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขคดีบริษัท:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {companyCode ? companyCode : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันรับเรื่อง:{" "}
                      <span style={{ marginLeft: 5 }}>
                        {dateStart
                          ? moment(dateStart)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      วันกำหนดส่ง:
                      <span style={{ marginLeft: 5 }}>
                        {deadline
                          ? moment(deadline)
                            .locale("th")
                            .add(543, "year")
                            .format("DD/MM/YYYY")
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      สถานะคดีปัจจุบัน:
                      <span style={{ marginLeft: 5 }}>{caseStatus}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: "16px" }}>
                      ผู้รับผิดชอบคดี:
                      <span style={{ marginLeft: 5 }}>
                        {chooseMemberCompanyName
                          ? chooseMemberCompanyName
                          : "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ) : null}

          {/* <Card sx={{ mb: 3 }}>
            <CardHeader
              sx={{ backgroundColor: "#181414" }}
              title="ข้อมูลบริษัท"
            />
            <CardContent sx={{ border: "0.5px solid" }}>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                  <Typography sx={{ fontSize: "16px" }}>เลขแฟ้ม:</Typography>
                  <TextField
                    type="text"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={fileNumber}
                    onChange={(e) => setFileNumber(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันที่รับเรื่อง:
                  </Typography>
                  <WatDatePicker
                    className="custom-date-picker"
                    value={dateStart}
                    onChange={(christDate, buddhistDate) =>
                      handleDatePickerChange(
                        setDateStart,
                        christDate,
                        buddhistDate
                      )
                    }
                    dateFormat={"yyyy-MM-dd"}
                    displayFormat={"DD/MM/YYYY"}
                    clearable={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                  <Typography sx={{ fontSize: "16px" }}>
                    วันกำหนดส่ง:
                  </Typography>
                  <WatDatePicker
                    className="custom-date-picker"
                    value={deadline}
                    onChange={(christDate, buddhistDate) =>
                      handleDatePickerChange(
                        setDeadline,
                        christDate,
                        buddhistDate
                      )
                    }
                    dateFormat={"yyyy-MM-dd"}
                    displayFormat={"DD/MM/YYYY"}
                    clearable={true}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                  <Typography sx={{ fontSize: "16px" }}>เลขคดีศาล:</Typography>
                  <TextField
                    type="text"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={courtCode}
                    onChange={(e) => setCourtCode(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เลขคดีบริษัท:
                  </Typography>
                  <TextField
                    type="text"
                    color="dark"
                    fullWidth
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    value={companyCode}
                    defaultValue={defaultCodeCompany}
                    disabled
                    onChange={(e) => setCompanyCode(e.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                  <Typography sx={{ fontSize: "16px" }}>
                    สถานะคดีปัจจุบัน:
                  </Typography>
                  <Select
                    value={caseStatus || ""}
                    onChange={(e) => setCaseStatus(e.target.value)}
                    variant="outlined"
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <MenuItem value="">โปรดระบุ</MenuItem>
                    <MenuItem value="อยู่ระหว่างลงพื้นที่ตรวจสอบ">
                      อยู่ระหว่างลงพื้นที่ตรวจสอบ
                    </MenuItem>
                    <MenuItem value="ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์ แต่ขอขยายระยะเวลาออกจากทรัพย์">
                      ผู้บุกรุกทรัพย์แจ้งจะออกจากทรัพย์
                      แต่ขอขยายระยะเวลาออกจากทรัพย์
                    </MenuItem>
                    <MenuItem value="อยู่ระหว่างเจรจาซื้อทรัพย์คืน">
                      อยู่ระหว่างเจรจาซื้อทรัพย์คืน
                    </MenuItem>
                    <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ออกหมายขับไล่">
                      อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย
                      ออกหมายขับไล่
                    </MenuItem>
                    <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการ  ออกหมายจับ">
                      อยู่ระหว่างสำนักงานทนายความดำเนินการ ออกหมายจับ
                    </MenuItem>
                    <MenuItem value="ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว">
                      ทรัพย์ไม่มีผู้อยู่อาศัยแล้ว
                    </MenuItem>
                    <MenuItem value="ส่งมอบทรัพย์แล้ว">
                      ส่งมอบทรัพย์แล้ว
                    </MenuItem>
                    <MenuItem value="อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย ประสานตำรวจจับกุม">
                      อยู่ระหว่างสำนักงานทนายความดำเนินการตามกฎหมาย
                      ประสานตำรวจจับกุม
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} sx={{mb:2}}>
                  <Typography sx={{ fontSize: "16px" }}>
                    ผู้รับผิดชอบคดีหลัก<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <Select
                    value={chooseMemberCompany}
                    fullWidth
                    color="dark"
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#ffffff",
                    }}
                    required
                  >
                    {userCompany
                      ? userCompany.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            name={item.fullname}
                            id={item.id}
                            onClick={(event) => handleWorkByChange(event)}
                          >
                            {item.fullname}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </Grid>
              </Grid>
            </CardContent>
          </Card> */}
        </Box>
      </Card>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsDetailExpal;
