import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {
  TextField,
  Card,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  List,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import UnpublishedRoundedIcon from "@mui/icons-material/UnpublishedRounded";
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import useAuth from "function/useAuth";
import config from "../../../config";
import api from "../../../api";

const DocumentsExecution = () => {
  const [user, setUser] = useState([]);
  const [document, setDocument] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openLed, setOpenLed] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [province, setProvince] = useState("");
  const [listProvince, setListProvince] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [checkFile, setCheckFile] = useState(false);
  const [type, setType] = useState("");
  const [typeCreate, setTypeCreate] = useState("");
  const [fileExport, setFileExport] = useState("");
  const [code, setCode] = useState("");
  const [nameCreate, setNameCreate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [findDefendent, setFindDefendent] = useState("");
  const [findDate, setFindDate] = useState("");
  const [findProvince, setFindProvince] = useState("");
  const [findDebtCenter, setFindDebtCenter] = useState("");
  const [findCourtCode, setFindCourtCode] = useState("");
  const [findCompanyCode, setFindCompanyCode] = useState("");
  const [defendantAccountNumber, setDefendantAccountNumber] = useState("");
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [debtCenterList, setDebtCenterList] = useState([]);
  const [progress, setProgress] = useState(null);
  // useAuth();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log("usereieina", userData);
    setUser(userData);
    if (userData) {
      getDocument(userData);
    }
    // getDdlDebtCenter();
  }, []);
  const navigate = useNavigate();

  function getDdlDebtCenter() {
    setLeft([]);
    setRight([]);
    setIsLoading(true);
    api
      .get(`${config.mainAPI}/debtCenter`)
      .then((response) => {
        setDebtCenterList(response.data.data);
        setLeft(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function getDdlDocument(userData) {
    setLeft([]);
    setRight([]);
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/documents/${userData.id}/${userData.role}`)
      .then((response) => {
        const value = response.data.data;
        setLeft(value);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  function handleDownload() {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/export-documents-excel`)
      .then((response) => {
        const download_url = `${config.mainAPI}/download-documents-excel`;
        window.location.href = download_url;
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function getDocument(userData) {
    setIsLoading(true);
    axios
      .get(`${config.mainAPI}/listExecution`)
      .then((response) => {
        console.log(response.data.data);
        const value = response.data.data;
        setData(value);
        // setLeft(value);
        // calculateProgress(value);
        if (value) {
          let getPercent = 0;
          // if(){
          //   getPercent += 14.826;
          // }
        console.log('value55555',value)

          setRows(
            value.map((item, index) => {
              // let getPercent = 0;
              // if (item.notic_out) {
              //   getPercent += 14.826;
              // }
              // if (item.notic_get) {
              //   getPercent += 14.826;
              // }
              // if (item.court_date) {
              //   getPercent += 14.826;
              // }
              // if (
              //   item.judgment_unilateral ||
              //   item.judgment_according_to_consent ||
              //   item.judgment_day
              // ) {
              //   getPercent += 14.826;
              // }
              // if (item.decree) {
              //   getPercent += 14.826;
              // }
              // if (item.issue_a_warran_of_execution) {
              //   getPercent += 14.826;
              // }

              // item.persent = Math.round(getPercent);
              item.persent = 0;
              console.log('itemmmm',item)

              return createData(
                index + 1,
                item.led_date_start,
                item.base_type,
                item.company_code_led,
                item.black_code_led,
                item.defendant_led,
                item.persent,
                item.updated_by_led,
                item.updated_at_led,
                item.document_code_gen_link
              );
            })
          );
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  function searchDocument() {
    setIsLoading(true);
    const payload = {
      defendent: findDefendent,
      date: findDate,
      province: findProvince,
      debtCenter: findDebtCenter,
      courtCode: findCourtCode,
      companyCode: findCompanyCode,
      defendantAccountNumber: defendantAccountNumber,
    };
    axios
      .post(`${config.mainAPI}/document-search`, {
        ...payload,
      })
      .then(function (response) {
        const value = response.data.data;

        // if (item.notic_out) {
        //   getPercent += 14.826;
        // }
        // if (item.notic_get) {
        //   getPercent += 14.826;
        // }
        // if (item.court_date) {
        //   getPercent += 14.826;
        // }
        // if (
        //   item.judgment_unilateral ||
        //   item.judgment_according_to_consent ||
        //   item.judgment_day
        // ) {
        //   getPercent += 14.826;
        // }
        // if (item.decree) {
        //   getPercent += 14.826;
        // }
        // if (item.issue_a_warran_of_execution) {
        //   getPercent += 14.826;
        // }

        item.persent = 0;
        console.log('value',value)
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
                item.led_date_start,
                item.base_type,
                item.company_code_led,
                item.black_code_led,
                item.defendant_led,
                item.persent,
                item.updated_by_led,
                item.updated_at_led,
              item.document_code_gen_link
            )
          )
        );
        setOpenSearch(false);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setOpenSearch(false);
        setIsLoading(false);
      });
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const columns = [
    { id: "order", label: "ลำดับที่", minWidth: 100 },
    { id: "date", label: "วันที่รับเรื่อง", minWidth: 100 },
    { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "code", label: "เลขคดีบริษัท", minWidth: 100 },
    { id: "black", label: "เลขคดีดำ", minWidth: 100 },
    // { id: "type", label: "ประเภท", minWidth: 100 },
    { id: "defendant", label: "จำเลย", minWidth: 100 },
    // { id: "persent", label: "สถานะ", minWidth: 100 },
    {
      id: "persent",
      label: "สถานะ",
      minWidth: 100,
      render: (row) => (
        <>
          <CircularProgressWithLabel value={row.persent} />
        </>
      ),
    },

    { id: "updated", label: "แก้ไขล่าสุด", minWidth: 100 },
    { id: "updated_by", label: "ผู้แก้ไข", minWidth: 100 },
    {
      id: "detail",
      label: "รายละเอียด",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "edit",
      label: "แก้ไข",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleEditForm(row)}>
            <EditIcon />
          </IconButton>
        </>
      ),
    },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleClickOpenDelete(row)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  function createData(
    order,
    date,
    type,
    code,
    black,
    defendant,
    persent,
    updated_by,
    updated,
    codegen,
    path
  ) {
    console.log('updated',updated)
    const formattedDate = moment(date)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY");
    const formattedDateUpdate = moment(updated)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY");
    return {
      order,
      date: date ? formattedDate : null,
      type,
      code,
      black,
      defendant,
      persent,
      updated_by,
      updated: updated ? formattedDateUpdate : null,
      codegen,
      path,
    };
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProvinceChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setProvince(value);
  };

  // function handleDownload(path) {
  //     const file_path = path;
  //     const download_url = `http://localhost:6969/download-file?file_path=${file_path}`;
  //     window.location.href = download_url;
  // }

  const handleClickOpenSearch = (row) => {
    setFindDefendent("");
    setFindDate("");
    setFindProvince("");
    setFindDebtCenter("");
    setFindCourtCode("");
    setFindCompanyCode("");
    setDefendantAccountNumber("");
    setOpenSearch(true);
  };

  const handleCloseSearch = (row) => {
    setFindDefendent("");
    setFindDate("");
    setFindProvince("");
    setFindDebtCenter("");
    setFindCourtCode("");
    setFindCompanyCode("");
    setDefendantAccountNumber("");
    setOpenSearch(false);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
    setTypeCreate("");
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
    setTypeCreate("");
  };

  const handleSubmitCreate = () => {
    if (typeCreate == "สินเชื่อ") {
      const genCode = `Thanic-Loan-${moment().format("YYMMDDHHmmssSS")}`;
      const payload = {
        genCode: genCode,
        user: user.username,
      };
      saveActivityLog(`Click create default form loan`);
      api
        .post(`${config.mainAPI}/createDefaultTemplateLoan`, {
          ...payload,
        })
        .then(function (response) {
          axios
            .post(`${config.mainAPI}/updateExecutionCase`, {
              id: genCode,
              type: "loan",
              user: user.username,
            })
            .then(function (response) {
              navigate("/loan/form", { state: { params: genCode } });
              setOpenCreate(false);
              setIsLoading(false);
              setTypeCreate("");
            })
            .catch(function (error) {
              console.log(error);
              setOpenCreate(false);
              setIsLoading(false);
              setTypeCreate("");
            });
        })
        .catch(function (error) {
          console.log(error);
          setOpenCreate(false);
          setIsLoading(false);
          setTypeCreate("");
        });
    } else if (typeCreate == "ขับไล่") {
      const genCode = `Thanic-Evication-${moment().format("YYMMDDHHmmssSS")}`;
      const payload = {
        genCode: genCode,
        user: user.username,
      };
      saveActivityLog(`Click create default form evication`);
      api
        .post(`${config.mainAPI}/createDefaultTemplateEvication`, {
          ...payload,
        })
        .then(function (response) {
          api
            .post(`${config.mainAPI}/updateExecutionCase`, {
              id: genCode,
              type: "expal",
              user: user.username,
            })
            .then(function (response) {
              setTypeCreate("");
              navigate("/expal/form", { state: { params: genCode } });
              setOpenCreate(false);
              setIsLoading(false);
            })
            .catch(function (error) {
              console.log(error);
              setOpenCreate(false);
              setTypeCreate("");
              setIsLoading(false);
            });
        })
        .catch(function (error) {
          console.log(error);
          setOpenCreate(false);
          setTypeCreate("");
          setIsLoading(false);
        });
    } else if (typeCreate == "บัตรเครดิต") {
      const genCode = `Thanic-Credit-${moment().format("YYMMDDHHmmssSS")}`;
      const payload = {
        genCode: genCode,
        user: user.username,
      };
      saveActivityLog(`Click create default form credit`);
      api
        .post(`${config.mainAPI}/createDefaultTemplateCredit`, {
          ...payload,
        })
        .then(function (response) {
          api
            .post(`${config.mainAPI}/updateExecutionCase`, {
              id: genCode,
              type: "credit",
              user: user.username,
            })
            .then(function (response) {
              setOpenCreate(false);
              setTypeCreate("");
              navigate("/credit/form", { state: { params: genCode } });
              setIsLoading(false);
            })
            .catch(function (error) {
              console.log(error);
              setTypeCreate("");
              setIsLoading(false);
              setOpenCreate(false);
            });
        })
        .catch(function (error) {
          console.log(error);
          setTypeCreate("");
          setIsLoading(false);
          setOpenCreate(false);
        });
    }
  };

  const handleClickOpenDelete = (row) => {
    console.log(row.codegen);
    setCode(row.codegen);
    setOpenDelete(true);
  };

  const handleCloseDelete = (row) => {
    setCode("");
    setOpenDelete(false);
  };

  const handleOpenMessage = (message) => {
    setOpenDelete(true);
  };

  const handleClosMessage = () => {
    setOpenMessage(false);
  };

  const handleClickCreate = () => {
    navigate("/loan/form");
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };


  const handleClickOpenImport = (row) => {
    setOpenImport(true);
  };

  const handleCloseImport = (row) => {
    setOpenImport(false);
  };

  const handleClickImport = () => {};

  const handleClickOpenExport = () => {
    setOpenExport(true);
  };

  const handleClickOpenLed = () => {
    setOpenLed(true);
  };

  const handleCloseLed = () => {
    setOpenLed(false);
  };

  const handleCloseExport = () => {
    setLeft([]);
    setRight([]);
    setType(null);
    setOpenExport(false);
  };

  const handleClickExport = () => {
    setType(null);
    console.log("right", right);
    setIsLoading(true);
    axios
      .post(`${config.mainAPI}/export-documents-excel-bycase`, {
        type: type,
        choose: right,
      })
      .then(function (response) {
        if (response.data.success) {
          setOpenExport(false);
          setLeft([]);
          setRight([]);
          const download_url = `${config.mainAPI}/download-documents-excel`;
          window.location.href = download_url;
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setOpenDelete(false);
        setIsLoading(false);
        console.log(error);
      });
  };

  // const handleChangeType = (event) => {
  //     const value = event.target.value;
  //     setType(value);
  //     setLeft(null);
  //     setRight(null);
  //     if (value == 'ระบุบุคคล') {
  //         getDdlDocument(user);
  //     }
  //     if (value == 'ระบุศูนย์หนี้') {
  //         getDdlDebtCenter();
  //     }
  // };

  const handleChangeType = (event) => {
    const value = event.target.value;
    setType(value);
    // setLeft(null);
    // setRight(null);
    if (value === "person") {
      getDdlDocument(user);
    }
    if (value === "debt_center") {
      getDdlDebtCenter();
    }
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).includes(searchTerm.toLowerCase());
    });
  });

  const handleEditForm = (row) => {
    navigate("/loan/form", { state: { params: row.codegen } });
  };

  const handleCheckForm = (row) => {
    navigate("/loan/detail", { state: { params: row.codegen } });
  };

  // const handleCheckForm = (row) => {
  //   navigate("/loan/form", { state: { params: row.codegen } });
  // };

  const handleDeleteForm = () => {
    setIsLoading(true);
    const userData = JSON.parse(localStorage.getItem("userData"));
    axios
      .post(`${config.mainAPI}/document-delete`, {
        genCode: code,
      })
      .then(function (response) {
        if (response.data.success) {
          setCode("");
          setOpenDelete(false);
          getDocument(userData);
        }
      })
      .catch(function (error) {
        setOpenDelete(false);
        setIsLoading(false);
        console.log(error);
      });
  };

  // const uploadFile = async (e) => {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('fileName', fileName);
  //     try {
  //         const res = await axios.post('http://localhost:7000/upload', formData);
  //         console.log(res);
  //         setFileName(res.data.name);
  //         setFilePath(res.data.path);
  //         setCheckFile(true);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  // function handleDownload(path) {
  //     const file_path = path;
  //     const download_url = `http://localhost:7000/download-file?file_path=${file_path}`;
  //     window.location.href = download_url;
  // }

  function calculateProgress(value) {}

  // https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleOpenSelectTypeForm = () => {};

  const customList = (title, items, type) => {
    if (type) {
      // Replace 'someType' with the actual type you're checking for
      return (
        <Card>
          <CardHeader
            sx={{ px: 2, py: 1 }}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={
                  numberOfChecked(items) === items.length && items.length !== 0
                }
                indeterminate={
                  numberOfChecked(items) !== items.length &&
                  numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                  "aria-label": "all items selected",
                }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} เคส`}
          />
          <Divider />
          <List
            sx={{
              width: 200,
              height: 230,
              bgcolor: "background.paper",
              overflow: "auto",
            }}
            dense
            component="div"
            role="list"
          >
            {items.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  {type == "person" ? (
                    <ListItemText
                      id={labelId}
                      primary={`${value.defendant_fullname}`}
                      primaryTypographyProps={{
                        style: {
                          color: "black",
                        },
                      }}
                    />
                  ) : null}
                  {type == "debt_center" ? (
                    <ListItemText
                      id={labelId}
                      primary={`${value.debt_center_name}`}
                      primaryTypographyProps={{
                        style: {
                          color: "black",
                        },
                      }}
                    />
                  ) : null}
                  {type == "led" ? (
                    <ListItemText
                      id={labelId}
                      primary={`${value.defendant_fullname}`}
                      primaryTypographyProps={{
                        style: {
                          color: "black",
                        },
                      }}
                    />
                  ) : null}
                </ListItem>
              );
            })}
          </List>
        </Card>
      );
    }
    return null;
  };

  // const customList = (title, items, type = null) => (
  // <Card sx={{ mt: 3, bgcolor: 'background.paper' }}>
  //     <CardHeader
  //         sx={{ px: 2, py: 1 }}
  //         avatar={
  //             <Checkbox
  //                 onClick={handleToggleAll(items)}
  //                 checked={Array.isArray(items) && numberOfChecked(items) === items.length && items.length !== 0}
  //                 indeterminate={Array.isArray(items) && numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
  //                 disabled={!Array.isArray(items) || items.length === 0}
  //                 inputProps={{
  //                     'aria-label': 'all items selected'
  //                 }}
  //             />
  //         }
  //         title={title}
  //         subheader={`${Array.isArray(items) ? numberOfChecked(items) : 0}/${Array.isArray(items) ? items.length : 0} เคส`}
  //     />
  //     <Divider />
  //     <List
  //         sx={{
  //             width: 200,
  //             height: 230,
  //             bgcolor: 'background.paper',
  //             overflow: 'auto'
  //         }}
  //         dense
  //         component="div"
  //         role="list"
  //     >
  //         {Array.isArray(items) &&
  //             items.map((value) => {
  //                 const labelId = `transfer-list-all-item-${value}-label`;

  //                 return (
  //                     <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
  //                         <ListItemIcon>
  //                             <Checkbox
  //                                 checked={checked.indexOf(value) !== -1}
  //                                 tabIndex={-1}
  //                                 disableRipple
  //                                 inputProps={{
  //                                     'aria-labelledby': labelId
  //                                 }}
  //                             />
  //                         </ListItemIcon>
  //                         {type === 'person' ? (
  //                             <ListItemText
  //                                 id={labelId}
  //                                 primary={`${value?.defendant_fullname}`}
  //                                 primaryTypographyProps={{
  //                                     style: {
  //                                         color: 'black'
  //                                     }
  //                                 }}
  //                             />
  //                         ) : null}

  //                         {type === 'debt_center' ? (
  //                             <ListItemText
  //                                 id={labelId}
  //                                 primary={`${value?.debt_center_name}`}
  //                                 primaryTypographyProps={{
  //                                     style: {
  //                                         color: 'black'
  //                                     }
  //                                 }}
  //                             />
  //                         ) : null}
  //                     </ListItem>
  //                 );
  //             })}
  //     </List>
  // </Card>
  // );

  // const customList = (title, items, type = null) => {
  //     return (
  //         <Card sx={{ mt: 3, bgcolor: 'background.paper' }}>
  //             <CardHeader
  //                 sx={{ px: 2, py: 1 }}
  //                 avatar={
  //                     <Checkbox
  //                         onClick={handleToggleAll(items)}
  //                         checked={Array.isArray(items) && numberOfChecked(items) === items.length && items.length !== 0}
  //                         indeterminate={Array.isArray(items) && numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
  //                         disabled={!Array.isArray(items) || items.length === 0}
  //                         inputProps={{
  //                             'aria-label': 'all items selected'
  //                         }}
  //                     />
  //                 }
  //                 title={title}
  //                 subheader={`${Array.isArray(items) ? numberOfChecked(items) : 0}/${Array.isArray(items) ? items.length : 0} เคส`}
  //             />
  //             <Divider />
  //             <List
  //                 sx={{
  //                     width: 200,
  //                     height: 230,
  //                     bgcolor: 'background.paper',
  //                     overflow: 'auto'
  //                 }}
  //                 dense
  //                 component="div"
  //                 role="list"
  //             >
  //                 {Array.isArray(items) &&
  //                     items.map((value) => {
  //                         const labelId = `transfer-list-all-item-${value}-label`;

  //                         return (
  //                             <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
  //                                 <ListItemIcon>
  //                                     <Checkbox
  //                                         checked={checked.indexOf(value) !== -1}
  //                                         tabIndex={-1}
  //                                         disableRipple
  //                                         inputProps={{
  //                                             'aria-labelledby': labelId
  //                                         }}
  //                                     />
  //                                 </ListItemIcon>
  //                                 {type === 'person' ? (
  //                                     <ListItemText
  //                                         id={labelId}
  //                                         primary={`${value?.defendant_fullname}`}
  //                                         primaryTypographyProps={{
  //                                             style: {
  //                                                 color: 'black'
  //                                             }
  //                                         }}
  //                                     />
  //                                 ) : null}

  //                                 {type === 'debt_center' ? (
  //                                     <ListItemText
  //                                         id={labelId}
  //                                         primary={`${value?.debt_center_name}`}
  //                                         primaryTypographyProps={{
  //                                             style: {
  //                                                 color: 'black'
  //                                             }
  //                                         }}
  //                                     />
  //                                 ) : null}
  //                             </ListItem>
  //                         );
  //                     })}
  //             </List>
  //         </Card>
  //     );
  // };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", marginTop: "10px" }}
        >
          รายการสืบทรัพย์บังคับคดี
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>ค้นหา</Typography>
          <TextField
            margin="dense"
            id="search"
            name="search"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginLeft: 3, width: "75%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
            float: "right",
          }}
        >
          {/* <Button
            variant="outlined"
            onClick={handleClickOpenExport}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FileOpenIcon />}
          >
            Export Excel
          </Button>

          <Button
            variant="outlined"
            onClick={handleClickOpenSearch}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            color="primary"
            startIcon={<FindInPageIcon />}
          >
            ค้นหา
          </Button> */}
          <Button
            variant="outlined"
            // onClick={handleClickCreateDefaultForm}
            onClick={handleOpenCreate}
            color="primary"
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มเอกสาร
          </Button>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Dialog open={openSearch} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseSearch}
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              ค้นหาเอกสาร
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จำเลย</Typography>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindDefendent(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เลขที่บัญชี</Typography>
                <TextField
                  type="text"
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setDefendantAccountNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>วันที่</Typography>
                <TextField
                  type="date"
                  sx={{
                    mt: 2,
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setFindDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>จังหวัด</Typography>
                <Select
                  value={province}
                  onChange={handleProvinceChange}
                  variant="outlined"
                  fullWidth
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {listProvince &&
                    listProvince.map((column) => (
                      <MenuItem key={column.id} value={column.name_th}>
                        {column.name_th}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เขตศูนย์หนี้</Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindDebtCenter(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>เลขคดีของศาล</Typography>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#ffffff",
                  }}
                  onChange={(e) => setFindCourtCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>
                  เลขคดีของบริษัท
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  onChange={(e) => setFindCompanyCode(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseSearch}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={searchDocument}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* <Dialog open={openExport} fullWidth maxWidth={'md'}>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseExport}>
                        <Typography variant="h4" sx={{ fontWeight: 500, textAlign: 'center', color: '#000' }}>
                            Export Excel
                        </Typography>
                    </BootstrapDialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontSize: '16px' }}>ประเภทคดี</Typography>
                                <Select
                                    onChange={(e) => setType(e.target.value)}
                                    variant="outlined"
                                    sx={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}
                                >
                                    <MenuItem value="สินเชื่อ">สินเชื่อ</MenuItem>
                                    <MenuItem value="ขับไล่">ขับไล่</MenuItem>
                                    <MenuItem value="บัตรเครดิต">บัตรเครดิต</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" color="error" onClick={handleCloseExport} sx={{ marginRight: 2 }}>
                                    ยกเลิก
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleClickExport}>
                                    ยืนยัน
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog> */}

        <Dialog open={openImport} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseImport}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              Import Excel
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>ประเภทคดี</Typography>
                <Select
                  onChange={(e) => setType(e.target.value)}
                  variant="outlined"
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <MenuItem value="สินเชื่อ">สินเชื่อ</MenuItem>
                  <MenuItem value="ขับไล่">ขับไล่</MenuItem>
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseImport}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickImport}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openLed} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseLed}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              เพิ่มรายการบังคับคดี
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>{customList("รายการทั้งหมด", left, "led")}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList("รายการที่ต้องการ", right, "led")}</Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openExport} fullWidth maxWidth={"md"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseExport}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              Export Excel
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography sx={{ fontSize: "16px" }}>
                  ประเภทการ Export
                </Typography>
                <Select
                  onChange={handleChangeType}
                  variant="outlined"
                  color="dark"
                  sx={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <MenuItem value="all">ทั้งหมด</MenuItem>
                  <MenuItem value="person">ระบุบุคคล</MenuItem>
                  <MenuItem value="debt_center">ระบุศูนย์หนี้</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {type == "person" ? (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>{customList("จำเลยทั้งหมด", left, "person")}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  {customList("จำเลยที่ต้องการ", right, "person")}
                </Grid>
              </Grid>
            ) : null}
            {type == "debt_center" ? (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  {customList("ศูนย์หนี้ทั้งหมด", left, "debt_center")}
                </Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  {customList("ศูนย์หนี้ที่ต้องการ", right, "debt_center")}
                </Grid>
              </Grid>
            ) : null}

            <Grid sx={{ mt: 1 }} container spacing={2} justifyContent="center">
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCloseExport}
                  sx={{ marginRight: 2 }}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickExport}
                >
                  ยืนยัน
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog open={openDelete} fullWidth maxWidth={"xs"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDelete}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff2929", fontSize: 100 }} />
            </Box>
            <Typography
              variant="h1"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              คำเตือน
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#1f1f1f",
              }}
            >
              หากลบแล้วเอกสารจะหายไป คุณต้องการที่จะลบเอกสารนี้ใช่หรือไม่?
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 3 }}
                onClick={handleDeleteForm}
              >
                ลบ
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseDelete}
              >
                ปิด
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={openCreate} fullWidth maxWidth={"xs"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseCreate}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginBottom: "20px",
                color: "#1f1f1f",
                fontSize: 18,
              }}
            >
              สร้างเอกสาร
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                color: "#1f1f1f",
                fontSize: 16,
              }}
            >
              โปรดเลือกประเภทเอกสาร:
            </Typography>
            <Select
              value={typeCreate}
              onChange={(e) => setTypeCreate(e.target.value)}
              variant="outlined"
              color="dark"
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <MenuItem value="สินเชื่อ">สินเชื่อ</MenuItem>
              <MenuItem value="ขับไล่">ขับไล่</MenuItem>
              <MenuItem value="บัตรเครดิต">บัตรเครดิต</MenuItem>
            </Select>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 3 }}
                onClick={handleCloseCreate}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmitCreate}
              >
                ยืนยัน
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        {/* <Dialog open={openMessage} fullWidth maxWidth={"xs"}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseMessage}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box textAlign="center">
              <ErrorIcon sx={{ color: "#ff2929", fontSize: 100 }} />
            </Box>
            <Typography
              variant="h1"
              sx={{ fontWeight: 500, textAlign: "center", color: "#000" }}
            >
              ข้อความแจ้งเตือน
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#1f1f1f",
              }}
            >
              หากลบแล้วเอกสารจะหายไป คุณต้องการที่จะลบเอกสารนี้ใช่หรือไม่?
            </Typography>
            <Box
              textAlign="center"
              sx={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{ mr: 3 }}
                onClick={handleDeleteForm}
              >
                ลบ
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                onClick={handleCloseDelete}
              >
                ปิด
              </Button>
            </Box>
          </DialogContent>
        </Dialog> */}
      </Card>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DocumentsExecution;
