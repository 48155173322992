import React, { useState, useEffect } from "react";
// import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../documents/styles/styles.css";
import api from "../../../api";
import lawImage from "../../../assets/images/mark-law.jpg";
import noimage from "../../../assets/images/users/noimg.png";
import config from "../../../config";
import { FileUploader } from "react-drag-drop-files";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import axios from "axios";
import moment from "moment/moment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
// import useAuth from "function/useAuth";

const Profile = () => {
  const [user, setUser] = useState();
  const [userName, setUserName] = useState(null);
  const [prefix, setPrefix] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [department, setDepartment] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [id, setId] = useState(null);
  const [code, setCode] = useState(null);
  const [role, setRole] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [birthDay, setBirthDay] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileUploadUser, setFileUploadUser] = useState(null);
  const [fileUploadSign, setFileUploadSign] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const [imageSign, setImageSign] = useState(null);
  const [value, setValue] = useState("recents");
  const [isLoading, setIsLoading] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [openSaveSuccess, setOpenSaveSuccess] = useState(false);
  const fileTypes = ["PNG", "JPG", "JPEG"];

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    setUser(JSON.parse(userData));
    if (userData) {
      // setId(JSON.parse(userData).id);
      // setFirstName(JSON.parse(userData).firstname);
      // setLastName(JSON.parse(userData).lastname);
      // setRole(JSON.parse(userData).position);
      // setUserName(JSON.parse(userData).username);
      getUserInfo(userData);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUserInfo = (value) => {
    setIsLoading(false);
    let userInfo = JSON.parse(value);
    let body = {
      username: userInfo.username,
    };
    api
      .post(`${config.mainAPI}/getUserInfo`, {
        ...body,
      })
      .then(function (response) {
        try {
          if (response && response.data && response.data.data) {
            console.log("response", response.data.data);
            let value = response.data.data;
            setUserInfo(value);
            setPrefix(value.prefix);
            setFirstName(value.firstname);
            setLastName(value.lastname);
            setRole(value.position);
            setIdCard(value.id_card);
            setEmail(value.email);
            setPhone(value.phone);
            setDepartment(value.department);
            setBirthDay(
              value.start_date_work
                ? moment(value.start_date_work)
                    .locale("th")
                    .add(543, "year")
                    .format("DD/MM/YYYY")
                : null
            );
            setStartDate(
              value.start_date_work
                ? moment(value.start_date_work)
                    .locale("th")
                    .add(543, "year")
                    .format("DD/MM/YYYY")
                : null
            );
            setNickName(value.nickname);
            setUserName(userInfo.username);
            // profile/user/username
            console.log("user", user);
            // setImageProfile(
            //   value.image
            //     ? `${config.mainAPI}/getImagesFromPathToUrl/${path}/${value.image}`
            //     : null
            // );
            setProfile(value);
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch(function (error) {
        console.log(error);
        // setOpenFailure(true);
      });
  };

  function setProfile(value) {
    const userData = localStorage.getItem("userData");
    let userInfoData = JSON.parse(userData);
    setImageProfile(
      value.image
        ? `${config.mainAPI}/getImagesProfileUrl/user/${userInfoData.username}/${value.image}`
        : null
    );
    setImageSign(
      value.image
        ? `${config.mainAPI}/getImagesProfileUrl/sign/${userInfoData.username}/${value.image}`
        : null
    );
  }
  function handleClearFileUpload() {
    setFileUpload(null);
    setFileUploadUser(null);
    setFileUploadSign(null);
  }

  const handleUploadChange = (file) => {
    setFileUpload(file);
    console.log(file);
  };

  const handleUploadChangeUser = (file) => {
    setFileUploadUser(file);
    console.log(file);
  };

  const handleUploadChangeSign = (file) => {
    setFileUploadSign(file);
    console.log(file);
  };

  const saveActivityLog = (message) => {
    const data = [
      {
        message: message,
        user: user.username,
      },
    ];
    axios.post(`${config.mainAPI}/activityLog`, data);
  };

  const handleSubmit = () => {
    const payload = {
      id: id,
      username: userName,
      prefix: prefix,
      firstName: firstName,
      lastName: lastName,
      full_name: `${prefix} ${firstName} ${lastName}`,
      id_card: idCard,
      phone: phone,
      email: email,
    };
    api
      .post(`${config.mainAPI}/updateUserInfo`, {
        ...payload,
      })
      .then(function (response) {
        if (response && response.data && response.data.success) {
          setOpenSaveSuccess(true);
        }
        // setOpenSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
        // setOpenFailure(true);
      });
  };

  const handleSubmitFileUpload = async (type) => {
    let fileUse =
      type == "user" ? fileUploadUser : type == "sign" ? fileUploadSign : null;
    if (fileUse) {
      console.log("fileUse", fileUse);
      const formData = new FormData();
      formData.append("fileUpload", fileUse);
      formData.append("fileName", fileUse.name);
      formData.append("type", type);
      formData.append("type_docs", type);
      formData.append("user", user.username);
      try {
        const res = await axios.post(
          `${config.mainAPI}/uploadFileProfile`,
          formData
        );
        saveActivityLog(`Upload File:${fileUse.name} Success`);
        setFileUpload(null);
        setFileUploadSign(null);
        setFileUploadUser(null);
      } catch (err) {
        console.log(err);
        saveActivityLog(`Upload File:${fileUse.name} Failure`);
      }
    }
  };

  const handleUploadImage = async (type) => {
    const formData = new FormData();
    formData.append("fileUpload", fileUse);
    formData.append("fileName", fileUse.name);
    formData.append("type", type);
    formData.append("username", type);
    formData.append("user", user.username);
    try {
      const res = await axios.post(
        `${config.mainAPI}/upload-file-full`,
        formData
      );
      saveActivityLog(`Upload File:${fileUse.name} Success`);
      setFileUpload(null);
      setFileUploadSign(null);
      setFileUploadUser(null);
    } catch (err) {
      console.log(err);
      saveActivityLog(`Upload File:${fileUse.name} Failure`);
    }
  };

  return (
    <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
      <Box sx={{ padding: "30px" }}>
        <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                padding: "1.5rem",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={2} sx={{ textAlign: "-webkit-center" }}>
                  {/* <Box
                    sx={{
                      bgcolor: "red",
                      borderRadius: 100,
                      width: "100px",
                      height: "100px",
                      textAlign: "-webkit-center",
                    }}
                  ></Box> */}
                  <img
                    src={imageProfile}
                    style={{
                      borderRadius: 100,
                      width: "100px",
                      height: "100px",
                      textAlign: "-webkit-center",
                    }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "20px",
                      textAlign: "left",
                      fontWeight: "700",
                    }}
                  >
                    {prefix} {firstName} {lastName}
                  </Typography>

                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "14px",
                      textAlign: "left",
                      mt: 2,
                      maxWidth: "fit-content",
                      padding: "4px",
                    }}
                  >
                    {role}{" "}
                    {department == "loan"
                      ? "สินเชื่อ"
                      : department == "credit"
                      ? "บัตรเครดิต"
                      : department == "expal"
                      ? "ขับไล่"
                      : department == "led"
                      ? "บังคับคดี"
                      : department}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
          <Grid item sm={8} xs={12}>
            {/* <Box
              sx={{
                padding: "1.5rem",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      placeContent: "center",
                    }}
                  >
                    <MedicationOutlinedIcon sx={{ mr: 2 }} /> Profile
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      placeContent: "center",
                    }}
                  >
                    <MedicationOutlinedIcon sx={{ mr: 2 }} /> Employee
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      placeContent: "center",
                    }}
                  >
                    <MedicationOutlinedIcon sx={{ mr: 2 }} /> ค่าลดหย่อน
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                      placeContent: "center",
                    }}
                  >
                    <MedicationOutlinedIcon sx={{ mr: 2 }} /> เอกสาร
                  </Typography>
                </Grid>
              </Grid>
            </Box> */}

            {/* mt: 3, */}

            <Box
              sx={{
                padding: "1.5rem",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "20px",
                      textAlign: "left",
                      fontWeight: "700",
                    }}
                  >
                    About
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      textAlign: "right",
                      fontWeight: "700",
                    }}
                  >
                    <EditOutlinedIcon />
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "18px",
                      textAlign: "left",
                    }}
                  >
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      mt: 2,
                      textAlign: "left",
                    }}
                  >
                    ชื่อ {prefix} {firstName} {lastName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      mt: 2,
                      textAlign: "left",
                    }}
                  >
                    ชื่อเล่น {userInfo?.nickname}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      mt: 2,
                    }}
                  >
                    วันเกิด {userInfo?.birth_day}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      mt: 2,
                    }}
                  >
                    วันเริ่มงาน {userInfo?.start_date_work}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      mt: 2,
                    }}
                  >
                    รูปภาพประจำตัว {userInfo?.start_date_work}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      mt: 2,
                    }}
                  >
                    ลายเซ็นต์ {userInfo?.start_date_work}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* <Box
              sx={{
                padding: "1.5rem",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                mt: 3,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "20px",
                      textAlign: "left",
                      fontWeight: "700",
                    }}
                  >
                    Image
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      textAlign: "right",
                      fontWeight: "700",
                    }}
                  >
                    <EditOutlinedIcon />
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "18px",
                      textAlign: "left",
                    }}
                  >
                    Profile
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FileUploader
                    multiple={false}
                    handleChange={handleUploadChangeUser}
                    name="fileUploadUser"
                    types={fileTypes}
                  />
                  <p style={{ marginTop: "1rem" }}>
                    {fileUploadUser ? `ชื่อไฟล์: ${fileUploadUser.name}` : ""}
                    {fileUploadUser ? (
                      <>
                        <IconButton onClick={handleClearFileUpload}>
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                        <IconButton
                          onClick={() => handleSubmitFileUpload("user")}
                        >
                          <UploadFileIcon color="success" />
                        </IconButton>
                      </>
                    ) : null}
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={4}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "18px",
                      textAlign: "left",
                    }}
                  >
                    Sign
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FileUploader
                    multiple={false}
                    handleChange={handleUploadChangeSign}
                    name="fileUploadSign"
                    types={fileTypes}
                  />
                  <p style={{ marginTop: "1rem" }}>
                    {fileUploadSign ? `ชื่อไฟล์: ${fileUploadSign.name}` : ""}
                    {fileUploadSign ? (
                      <>
                        <IconButton onClick={handleClearFileUpload}>
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                        <IconButton
                          onClick={() => handleSubmitFileUpload("sign")}
                        >
                          <UploadFileIcon color="success" />
                        </IconButton>
                      </>
                    ) : null}
                  </p>
                </Grid>
              </Grid>
            </Box> */}
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box
              sx={{
                padding: "1.5rem",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                height: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    Absense
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="year"
                    sx={{
                      fontSize: "20px",
                      fontWeight: "700",
                      textAlign: "right",
                    }}
                  >
                    2566
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={6}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MedicationOutlinedIcon sx={{ mr: 2 }} /> ลาป่วย
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="year"
                    sx={{
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    คงเหลือ {userInfo?.sick_leave ? userInfo?.sick_leave : 0}{" "}
                    วัน
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={6}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NotificationsOffOutlinedIcon sx={{ mr: 2 }} /> ลากิจ
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="year"
                    sx={{
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    คงเหลือ{" "}
                    {userInfo?.bussiness_leave ? userInfo?.bussiness_leave : 0}{" "}
                    วัน
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={6}>
                  <Typography
                    id="heading"
                    sx={{
                      fontSize: "16px",
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <WbSunnyOutlinedIcon sx={{ mr: 2 }} /> ลาพักร้อน
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    id="year"
                    sx={{
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    คงเหลือ{" "}
                    {userInfo?.vacation_leave ? userInfo?.vacation_leave : 0}{" "}
                    วัน
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {/* <Card>
              <CardHeader>
                <Typography sx={{ fontSize: "16px" }}>Absense</Typography>
              </CardHeader>
              <Box>
                <Card>
                  <CardHeader sx={{ color: "#48495e" }} title="Absense" />
                  <CardContent sx={{ border: " 0.5px solid" }}>
                    <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                      <Grid item sm={6} xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>
                          รหัสพนักงาน:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          value={code}
                          disabled
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>
                          ตำแหน่ง:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          disabled
                          value={role}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                      <Grid item sm={4} xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>
                          คำนำหน้าชื่อ:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          value={prefix}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>ชื่อ:</Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          value={firstName}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>
                          นามสกุล:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          value={lastName}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                      <Grid item sm={4} xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>
                          เลขบัตรประชาชน:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          value={idCard}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>
                          เบอร์ติดต่อ:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          value={phone}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography sx={{ fontSize: "16px" }}>
                          อีเมล์:
                        </Typography>
                        <TextField
                          type="text"
                          color="dark"
                          fullWidth
                          value={email}
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            backgroundColor: "#ffffff",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Button
                          variant="contained"
                          sx={{
                            marginRight: "20px",
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                          color="success"
                          onClick={handleSubmit}
                          // startIcon={<DoneAllIcon />}
                        >
                          อัปเดตข้อมูล
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Card> */}
          </Grid>
        </Grid>

        {/* <Card>
          <CardHeader>
            <Typography sx={{ fontSize: "16px" }}>ข้อมูลส่วนตัว:</Typography>
          </CardHeader>
          <Box>
            <Card>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลส่วนตัว"
              />
              <CardContent sx={{ border: " 0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                  <Grid item sm={6} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      รหัสพนักงาน:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={code}
                      disabled
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>ตำแหน่ง:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      disabled
                      value={role}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      คำนำหน้าชื่อ:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={prefix}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>ชื่อ:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={firstName}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>นามสกุล:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={lastName}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เลขบัตรประชาชน:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={idCard}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>
                      เบอร์ติดต่อ:
                    </Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={phone}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography sx={{ fontSize: "16px" }}>อีเมล์:</Typography>
                    <TextField
                      type="text"
                      color="dark"
                      fullWidth
                      value={email}
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        marginRight: "20px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                      color="success"
                      onClick={handleSubmit}
                      // startIcon={<DoneAllIcon />}
                    >
                      อัปเดตข้อมูล
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Card> */}

        {/* <Card>
          <CardHeader>
            <Typography sx={{ fontSize: "16px" }}>
              รายละเอียดเพิ่มเติม:
            </Typography>
          </CardHeader>
          <Box>
            <Card>
              <CardHeader
                sx={{ backgroundColor: "#181414" }}
                title="ข้อมูลภาพ"
              />
              <CardContent sx={{ border: " 0.5px solid" }}>
                <Grid container spacing={2} sx={{ mb: 2, padding: 2 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px", mb: 1 }}>
                      ภาพประจำตัว:
                    </Typography>
                    <FileUploader
                      multiple={false}
                      handleChange={handleUploadChangeUser}
                      name="fileUploadUser"
                      types={fileTypes}
                    />
                    <p style={{ marginTop: "1rem" }}>
                      {fileUploadUser ? `ชื่อไฟล์: ${fileUploadUser.name}` : ""}
                      {fileUploadUser ? (
                        <>
                          <IconButton onClick={handleClearFileUpload}>
                            <DeleteForeverIcon color="error" />
                          </IconButton>
                          <IconButton
                            onClick={() => handleSubmitFileUpload("user")}
                          >
                            <UploadFileIcon color="success" />
                          </IconButton>
                        </>
                      ) : null}
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontSize: "16px", mb: 1 }}>
                      ลายเซ็นต์:
                    </Typography>
                    <FileUploader
                      multiple={false}
                      handleChange={handleUploadChangeSign}
                      name="fileUploadSign"
                      types={fileTypes}
                    />
                    <p style={{ marginTop: "1rem" }}>
                      {fileUploadSign ? `ชื่อไฟล์: ${fileUploadSign.name}` : ""}
                      {fileUploadSign ? (
                        <>
                          <IconButton onClick={handleClearFileUpload}>
                            <DeleteForeverIcon color="error" />
                          </IconButton>
                          <IconButton
                            onClick={() => handleSubmitFileUpload("sign")}
                          >
                            <UploadFileIcon color="success" />
                          </IconButton>
                        </>
                      ) : null}
                    </p>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Card> */}
      </Box>

      <Dialog open={openSaveSuccess} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Box textAlign="center">
            <ErrorIcon sx={{ color: "#086c3c", fontSize: 100 }} />
          </Box>

          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#086c3c",
              fontSize: 16,
            }}
          >
            ระบบบันทึกข้อมูลสำเร็จ
          </Typography>
          <Box
            textAlign="center"
            sx={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <Button
              variant="outlined"
              color="success"
              onClick={() => setOpenSaveSuccess(false)}
            >
              ปิด
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Profile;
