import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import config from "../../../config";
import api from "../../../api";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert,
  Divider,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
// import useAuth from "../../function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PaidIcon from "@mui/icons-material/Paid";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";

const SalaryDetail = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [idCard, setIdCard] = useState("");
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFirstTimeLogin, setOpenFirstTimeLogin] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  //   const localizer = momentLocalizer(moment);
  const [eventCalendar, setEventCalendar] = useState([]);
  const [textCalendar, setTextCalendar] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [openCreate, setOpenCreate] = useState(false);
  const [openPaid, setOpenPaid] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [dateWork, setDateWork] = useState(new Date());
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [detail, setDetail] = useState("");
  const [hourWork, setHourWork] = useState(null);
  const [timeStart, setTimeStart] = useState(null);
  const [leave, setLeave] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);
  const [error, setError] = useState(null);
  const [roundDate, setRoundDate] = useState(new Date());
  const { state } = useLocation();
  const [userList, setUserList] = useState([]);

  const [userChoose, setUserChoose] = useState("");
  const [salary, setSalary] = useState(0);
  const [ot, setOt] = useState(0);
  const [optional, setOptional] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [benefit, setBenefit] = useState(0);
  const [tax, setTax] = useState(0);
  const [sso, setSso] = useState(0);
  const [leaveDeduction, setLeaveDeduction] = useState(0);
  const [lateDeduction, setLateDeduction] = useState(0);
  const [absentDeduction, setAbsentDeduction] = useState(0);
  const [featureDeduction, setFeatureDeduction] = useState(0);
  const [etcDeduction, setEtcDeduction] = useState(0);
  const [note, setNote] = useState("");
  const [sumAll, setSumAll] = useState(0);
  const [sumGetAll, setSumGetAll] = useState(0);
  const [sumDeductionAll, setSumDeductionAll] = useState(0);
  const [action, setAction] = useState("ADD");
  const [id, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const columns = [
    {
      id: "action",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleEditForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    { id: "order", label: "ลำดับ", minWidth: 100 },
    { id: "name", label: "ชื่อ-สกุล", minWidth: 100 },
    { id: "money", label: "ยอดเงิน", minWidth: 100 },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton
            disabled={row.status == "PAID"}
            onClick={() => handleDeleteForm(row)}
          >
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditForm = (row) => {
    // setOpenCreate(true);
    getSalaryDetail(row);
    setId(row.id);
  };

  const getSalaryDetail = (row) => {
    let body = {
      account_id: state.params,
      id: row.id,
    };
    setAction("EDIT");
    api
      .post(`${config.mainAPI}/getSalaryDetail`, body)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          let value = response.data.data[0];
          console.log("value", value);
          setBenefit(value.benefit);
          setEtcDeduction(value.etc_deduction);
          setFeatureDeduction(value.feature_deduction);
          setLateDeduction(value.late_deduction);
          setLeaveDeduction(value.leave_deduction);
          setOptional(value.optional);
          setOt(value.ot);
          setPercentage(value.percentage);
          setSalary(value.salary);
          setUserChoose(value.user_username);
          setSso(value.sso);
          setTax(value.tax);
          setOpenCreate(true);
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleDeleteForm = (row) => {
    console.log("row", row);
    setId(row.id);
    setOpenDelete(true);
  };

  const deleteMoneyInfo = () => {
    console.log("id", id);
    let body = {
      id: id,
      round: state.round,
      account_id: state.params,
    };
    api
      .post(`${config.mainAPI}/deleteAccountMember`, body)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          closePopup();
          getInitialData(user);
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleCheckForm = (row) => {
    navigate("/salary-detail", { state: { params: row.id } });
  };

  const closePopup = () => {
    setError(null);
    setOpenCreate(false);
    setSalary(0);
    setOt(0);
    setOptional(0);
    setPercentage(0);
    setBenefit(0);
    setTax(0);
    setSso(0);
    setLeaveDeduction(0);
    setLateDeduction(0);
    setAbsentDeduction(0);
    setFeatureDeduction(0);
    setEtcDeduction(0);
    setNote("");
    setSumAll(0);
    setSumGetAll(0);
    setSumDeductionAll(0);
    setUserChoose("");
    setAction("ADD");
    setOpenDelete(false);
    setId("");
  };

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return String(value).toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  function createData(order, name, money, accountId, id) {
    return {
      order,
      name,
      money: money ? money : "-",
      accountId,
      id,
    };
  }

  const saveData = () => {
    let body = {
      account_id: state.params,
      salary: salary,
      ot: ot,
      optional: optional,
      percentage: percentage,
      benefit: benefit,
      tax: tax,
      sso: sso,
      leave_deduction: leaveDeduction,
      late_deduction: lateDeduction,
      absent_deduction: absentDeduction,
      feature_deduction: featureDeduction,
      etc_deduction: etcDeduction,
      note: note,
      userChoose: userChoose,
      username: user.username,
      round: state.round,
      sumAll: sumAll,
      sumDeductionAll: sumDeductionAll,
      sumGetAll: sumGetAll,
      id: id,
    };
    let apiName =
      action == "EDIT" ? "updateAccountMember" : "createAccountMember";
    api
      .post(`${config.mainAPI}/${apiName}`, body)
      .then(function (response) {
        setIsLoading(false);
        console.log('response.data',response.data);
        if (response.data.success) {
          closePopup();
          getInitialData(user);
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const updateStatusPaid = () => {
    let body = {
      account_id: null,
      username: user.username,
    };
    api
      .post(`${config.mainAPI}/updateStatusPaid`, body)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          closePopup();
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log("state", state);
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      console.log("userData", userData);
      setUser(userData);
      getListUserCompany();
      getInitialData(userData);
    }
  }, []);

  useEffect(() => {
    calGetAll();
  }, [salary, ot, optional, benefit]);

  useEffect(() => {
    calDedectionAll();
  }, [
    tax,
    sso,
    leaveDeduction,
    lateDeduction,
    absentDeduction,
    featureDeduction,
    etcDeduction,
  ]);

  useEffect(() => {
    calAll();
  }, [sumGetAll, sumDeductionAll]);

  const calGetAll = () => {
    let salaryValue = parseFloat(salary) || 0;
    let otValue = parseFloat(ot) || 0;
    let optionalValue = parseFloat(optional) || 0;
    let benefitValue = parseFloat(benefit) || 0;
    let sum = salaryValue + otValue + optionalValue + benefitValue;
    setSumGetAll(sum.toString());
  };

  const calDedectionAll = () => {
    let ssoValue = parseFloat(sso) || 0;
    let taxValue = parseFloat(tax) || 0;
    let leaveDeductionValue = parseFloat(leaveDeduction) || 0;
    let lateDeductionValue = parseFloat(lateDeduction) || 0;
    let absentDeductionValue = parseFloat(absentDeduction) || 0;
    let featureDeductionValue = parseFloat(featureDeduction) || 0;
    let etcDeductionValue = parseFloat(etcDeduction) || 0;

    let sum =
      ssoValue +
      taxValue +
      leaveDeductionValue +
      lateDeductionValue +
      absentDeductionValue +
      featureDeductionValue +
      etcDeductionValue;
    setSumDeductionAll(sum.toString());
  };

  const calAll = () => {
    const sumGetAllValue = parseFloat(sumGetAll) || 0;
    const lsumDeductionAllValue = parseFloat(sumDeductionAll) || 0;

    let sum = sumGetAllValue - lsumDeductionAllValue;
    setSumAll(sum.toString());
  };

  const getListUserCompany = () => {
    setIsLoading(true);
    api
      .get(`${config.mainAPI}/getListUserCompany`)
      .then((response) => {
        const value = response.data.data;
        console.log("getListUserCompany", value);
        setUserList(value);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const getInitialData = (userData) => {
    setIsLoading(true);
    const body = {
      account_id: state.params,
      round: state.round,
    };
    api
      .post(`${config.mainAPI}/listAccountMemberAll`, body)
      .then((response) => {
        console.log(response.data.data);
        const value = response.data.data;
        console.log("value", value);
        setList(value);
        setRows(
          value.map((item, index) =>
            createData(
              index + 1,
              item.user_fullname,
              item.sum_all,
              item.account_id,
              item.id
            )
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleClickOpenEdit = () => {
    setOpenCreate(true);
    setAction("EDIT");
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "red" : event.color,
    };
    return {
      style,
    };
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setError(null);
    setData(christDate);
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          รายละเอียดเงินเดือนรอบที่{state.round} ({state.date})
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: 3,
            marginTop: 3,
            float: "right",
          }}
        >
          {/* <Button
            variant="contained"
            color="success"
            onClick={() => setOpenPaid(true)}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
            }}
            startIcon={<PaidIcon />}
          >
            ยืนยันการชำระเงินเดือน
          </Button> */}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenCreate(true)}
            sx={{
              marginRight: "20px",
              marginTop: "10px",
              marginBottom: "20px",
              color: "#000",
              borderColor: "#000",
            }}
            startIcon={<AddCircleIcon />}
          >
            เพิ่มผู้รับเงิน
          </Button>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>

      <Dialog open={openCreate} fullWidth={true} maxWidth={"xl"}>
        <DialogTitle sx={{ fontWeight: 500, fontSize: 20 }}>
          เพิ่มผู้รับเงิน {/* Title for the dialog */}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontSize: "16px" }}>ระบุพนักงาน</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>พนักงาน</Typography>
                      <Select
                        value={userChoose}
                        onChange={(e) => setUserChoose(e.target.value)}
                        variant="outlined"
                        fullWidth
                        color="dark"
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        {userList &&
                          userList.map((column) => (
                            <MenuItem value={column.username}>
                              {column.fullName}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontSize: "16px" }}>รายการรับ</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินเดือน
                      </Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={salary}
                        onChange={(e) => {
                          setSalary(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ค่าล่วงเวลา
                      </Typography>

                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={ot}
                        onChange={(e) => {
                          setOt(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินเพิ่มเติม
                      </Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={optional}
                        onChange={(e) => {
                          setOptional(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        เงินพิเศษ
                      </Typography>

                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={benefit}
                        onChange={(e) => {
                          setBenefit(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมยอดรับ
                      </Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={sumGetAll}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontSize: "16px" }}>รายการหัก</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ณ ที่จ่าย
                      </Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={tax}
                        onChange={(e) => {
                          setTax(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ประกันสังคม
                      </Typography>

                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={sso}
                        onChange={(e) => {
                          setSso(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>หักลา</Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={leaveDeduction}
                        onChange={(e) => {
                          setLeaveDeduction(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หักมาสาย
                      </Typography>

                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={lateDeduction}
                        onChange={(e) => {
                          setLateDeduction(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>หักขาด</Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={absentDeduction}
                        onChange={(e) => {
                          setAbsentDeduction(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หักล่วงหน้า
                      </Typography>

                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={featureDeduction}
                        onChange={(e) => {
                          setFeatureDeduction(e.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หักอื่นๆ
                      </Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={etcDeduction}
                        onChange={(e) => {
                          setEtcDeduction(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        หมายเหตุ
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        รวมยอดหัก
                      </Typography>
                      <TextField
                        type="text"
                        color="dark"
                        fullWidth
                        disabled
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        value={sumDeductionAll}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Grid container spacing={2} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontSize: "16px" }}>สรุปรายการ</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontSize: "16px" }}>
                        ยอดเงินสุทธิ
                      </Typography>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        color="dark"
                        fullWidth
                        sx={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          backgroundColor: "#ffffff",
                        }}
                        disabled
                        value={sumAll}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider />

          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => closePopup()}
              sx={{ marginRight: 2 }}
            >
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              color="success"
              disabled={error}
              onClick={() => saveData()}
            >
              บันทึก
            </Button>
          </Box>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Dialog open={openPaid} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            ยืนยันการชำระเงินเดือน
          </Typography>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpenPaid(false)}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => updateStatusPaid()}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Dialog open={openDelete} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            ยืนยันการลบ
          </Typography>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpenDelete(false)}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => deleteMoneyInfo()}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default SalaryDetail;
